import React, { memo, useRef, useState } from 'react';
import { cx } from '@utils/cx';
import { Input, Spin, Switch } from 'antd';
import { Select } from 'global';
import { useTranslation } from 'react-i18next';

import SuccessIcon from '../../assets/icons/SuccessIcon';
import { useUpdateSmsConfig } from '../../queries/mutation';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../utils/helper/helper';
import { MessageConfig } from '../../utils/models/MessageConfig';

import styles from '../../pages/messageSettings/MessageSettings.module.scss';

interface Props {
  message: MessageConfig;
}


const SendingMessageCard: React.FC<Props> = ({ message }) => {
  const [interval, setInterval] = useState<number | null>(message.interval);
  const updateType = useRef<'active' | 'interval' | 'sendToAll'>('active');
  const { t } = useTranslation();
  const updateMessage = useUpdateSmsConfig(message.smsTypeDifComDto.type);

  const handleIntervalChange = (e: any) => {
    setInterval(helper.deleteNotNumbers(e.target.value, null));
  };

  const handleSaveInterval = () => {
    updateType.current = 'interval';
    if (interval) {
      updateMessage.mutate({
        active: message?.active,
        interval,
        sendToAll: message?.sendToAll
      });

    }
  };

  const handleChangeActive = (e: boolean) => {
    updateType.current = 'active';
    updateMessage.mutate({
      active: e,
      interval,
      sendToAll: message?.sendToAll
    });
  };

  const handleChange = (value: boolean | null) => {
    updateType.current = 'sendToAll';
    updateMessage.mutate({
      active: message?.active,
      interval,
      sendToAll: value !== null ? value : false,
    });
  };

  return (
    <div className={cx(styles.card)}>
      <div className={cx(styles.cardTitle, 'h-[25px]')}>
        <p>{getCurrentLanguageWord(message.smsTypeDifComDto.name)}</p>
        <Switch
          loading={updateMessage.isLoading && updateType.current === 'active'}
          onChange={handleChangeActive}
          checked={message.active}
          size="small"
        />
      </div>

      <div className='h-[60px]'>
        <p>{getCurrentLanguageWord(message.smsTypeDifComDto.def)}</p>
      </div>

      <div className='mb-2'>
        <Select
          value={message.sendToAll}
          style={{ width: '100%' }}
          onChange={handleChange}
          className='customSelect'
        >
          <Select.Option value={false}>{t('boss_crm.Aktiv')}</Select.Option>
          <Select.Option value={true}>{t('boss_crm.Barchasi')}</Select.Option>
        </Select>
      </div>

      {message?.smsTypeDifComDto?.type === "MONTH_PAYMENT_ONE_DAY_AFTER" && message.interval && (
        <div className='mt-[10px]'>
          <div className={cx(styles.cardTitle, styles.cardTitle_interval)}>
            <p>{t('message_settings.SMS_yuborish_intervali_kun')}</p>
          </div>

          <div className={styles.interval}>
            <Input
              value={interval as any}
              onChange={handleIntervalChange}
              className={interval ? styles.interval_input : styles.interval_input_error}
            />
            <Spin spinning={updateMessage.isLoading && updateType.current === 'interval'}>
              <p onClick={handleSaveInterval} className={styles.saveButton}>
                <span>
                  <SuccessIcon />
                </span>
                {t('home.Saqlash')}
              </p>
            </Spin>
          </div>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line max-len
const isEqual = (prevProps: Props, nextProps: Props) => prevProps.message.active === nextProps.message.active && prevProps.message.interval === nextProps.message.interval && prevProps.message.sendToAll === nextProps.message.sendToAll;

export default memo(SendingMessageCard, isEqual);
