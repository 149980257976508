import React, { FC } from 'react';
import HouseItem from '@pages/contract/components/table/houseItem/HouseItem';
import { Checkbox, Collapse, Drawer, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ColapseIcon from '../../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useHousesNameAndID, useRegions } from '../../../queries/queries';
import DatePicker from '../../../service/datePicker';
import { DATE_FORMAT } from '../../../utils/constants/format';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { ClientStatus } from '../../../utils/models/Client';
import { SearchParamsClient } from '../Client';

import '@components/filters/filterDrawer.module.scss';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface ContractFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const ClientFilterDrawer: FC<ContractFilterDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, removeAllSearchParams, remove, appendMultiple, appendSingle } = useQueryParam<SearchParamsClient, any>();
  const { t } = useTranslation();

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: houses } = useHousesNameAndID();

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };

  const changeContractStatus = (value: ClientStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else if (searchParams.statuses === value) {
      remove('statuses', value);
    } else {
      appendMultiple('statuses', value);
    }
  };

  const changeCities = (id: string) => {
    if (Array.isArray(searchParams.cities)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.cities.includes(id) ? remove('cities', id) : appendMultiple('cities', id);
    } else if (searchParams.cities === id) {
      remove('cities', id);
    } else {
      appendMultiple('cities', id);
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className="filter_drawer">
      <Spin spinning={loadingRegions}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Sana')}
              </>
            }
            key="1"
          >
            <RangePicker
              format={DATE_FORMAT}
              value={searchParams.startDate && searchParams.finishDate ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)] : [null, null]}
              onChange={changeDate}
              className="CustomRangePicker"
              placeholder={['kk.oo.yyyy', 'kk.oo.yyyy']}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Holati')}
              </>
            }
            key="2"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeContractStatus(ClientStatus.NEW)}
                checked={Array.isArray(searchParams.statuses) ? searchParams.statuses.includes(ClientStatus.NEW) : searchParams.statuses === ClientStatus.NEW}
              >
                {t(`home.${ClientStatus.NEW}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ClientStatus.ACTIVE)}
                checked={Array.isArray(searchParams.statuses) ? searchParams.statuses.includes(ClientStatus.ACTIVE) : searchParams.statuses === ClientStatus.ACTIVE}
              >
                {t(`home.${ClientStatus.ACTIVE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ClientStatus.PASSIVE)}
                checked={Array.isArray(searchParams.statuses) ? searchParams.statuses.includes(ClientStatus.PASSIVE) : searchParams.statuses === ClientStatus.PASSIVE}
              >
                {t(`home.${ClientStatus.PASSIVE}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Yashash_joyi')}
              </>
            }
            key="3"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {regions?.map((house, index) => (
                <Checkbox
                  checked={Array.isArray(searchParams.cities) ? searchParams.cities.includes(String(house.id)) : searchParams.cities === String(house.id)}
                  onChange={() => changeCities(String(house.id))}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {getCurrentLanguageWord(house)}
                </Checkbox>
              ))}
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Passport')}
              </>
            }
            key="4"
          >
            <Checkbox
              checked={Boolean(searchParams?.expired)}
              onChange={e => {
                if (e?.target?.checked) {
                  appendSingle('expired', String(e?.target?.checked));
                } else {
                  remove('expired', '');
                }
              }}
            >
              {t('home.EXPIRED')}
            </Checkbox>
          </Panel>
          <Panel
            className="house_item"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="5"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => (
                <HouseItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  id={house.id || -1}
                  name={house.name}
                />
              ))}
            </Space>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default ClientFilterDrawer;
