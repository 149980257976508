import { SearchParamsArrearage } from '../../pages/arrearage/Arrearage';
import { SearchParamsBroker } from '../../pages/broker/Broker';
import { SearchParamsClient } from '../../pages/client/Client';
import { SearchParamsContract } from '../../pages/contract/Contract';
import { SearchParamsCrm } from '../../pages/crm/components/filter/FilterButton';
import { SearchParamsHome } from '../../pages/home/Home';
import { SearchParamsMessage } from '../../pages/message/Message';
import { SearchParamsOrder } from '../../pages/order/Order';
import { SearchParamsPayment } from '../../pages/payment/Payment';
import { SearchParamsTask } from '../models/FilterTask';

import { urlConvertKeyValue } from './urlConvertKeyValue';
import { urlDecode } from './urlEncodeDecode';

export const getFlatsBody = (size: number, searchObj: SearchParamsHome) => {
  const newSearchObj = { ...searchObj };
  const houses: string[] = [];
  const buildings: string[] = [];
  const rooms = [];
  const saleStatus = [];

  newSearchObj.order = undefined;
  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }
  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }
  if (Array.isArray(newSearchObj.saleStatuses)) {
    newSearchObj.saleStatuses.forEach(status => {
      saleStatus.push(status);
    });
  } else if (newSearchObj.saleStatuses) {
    saleStatus.push(newSearchObj.saleStatuses);
  }
  if (Array.isArray(newSearchObj.rooms)) {
    newSearchObj.rooms.forEach(id => {
      rooms.push(id);
    });
  } else if (newSearchObj.rooms) {
    rooms.push(newSearchObj.rooms);
  }
  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;
  newSearchObj.saleStatuses = saleStatus.length > 0 ? saleStatus : undefined;
  newSearchObj.rooms = rooms.length > 0 ? rooms : undefined;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }
  return newSearchObj;
};

export const getOrdersBody = (size: number, searchObj: SearchParamsOrder) => {
  const newSearchObj = { ...searchObj };
  const houses: string[] = [];
  const statuses: string[] = [];
  const buildings: string[] = [];
  const currencies: string[] = [];
  const responsibleByIds: string[] = [];

  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }
  if (Array.isArray(newSearchObj.currencies)) {
    newSearchObj.currencies.forEach(id => {
      currencies.push(id);
    });
  } else if (newSearchObj.currencies) {
    currencies.push(newSearchObj.currencies);
  }
  if (Array.isArray(newSearchObj.responsibleByIds)) {
    newSearchObj.responsibleByIds.forEach(id => {
      responsibleByIds.push(id);
    });
  } else if (newSearchObj.responsibleByIds) {
    responsibleByIds.push(newSearchObj.responsibleByIds);
  }
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;
  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.statuses = statuses.length > 0 ? statuses : undefined;
  newSearchObj.currencies = currencies.length > 0 ? currencies : undefined;
  newSearchObj.responsibleByIds = responsibleByIds.length > 0 ? responsibleByIds : undefined;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }
  return newSearchObj;
};

export const getContractsBody = (size: number, searchObj: SearchParamsContract) => {
  const newSearchObj = { ...searchObj };

  newSearchObj.order = undefined;
  const statuses: string[] = [];
  const houses: string[] = [];
  const buildings: string[] = [];
  const responsibleByIds: string[] = [];
  const color: string[] = [];
  const currencies: string[] = [];

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }
  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.responsibleByIds)) {
    newSearchObj.responsibleByIds.forEach(id => {
      responsibleByIds.push(id);
    });
  } else if (newSearchObj.responsibleByIds) {
    responsibleByIds.push(newSearchObj.responsibleByIds);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }
  if (Array.isArray(newSearchObj.currencies)) {
    newSearchObj.currencies.forEach(id => {
      currencies.push(id);
    });
  } else if (newSearchObj.currencies) {
    currencies.push(newSearchObj.currencies);
  }
  if (Array.isArray(newSearchObj.color)) {
    newSearchObj.color.forEach(id => {
      color.push(id);
    });
  } else if (newSearchObj.color) {
    color.push(newSearchObj.color);
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.statuses = statuses.length > 0 ? statuses : undefined;
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;
  newSearchObj.currencies = currencies.length > 0 ? currencies : undefined;
  newSearchObj.responsibleByIds = responsibleByIds.length > 0 ? responsibleByIds : undefined;
  newSearchObj.color = color.length > 0 ? color : undefined;
  newSearchObj.size = String(size) || '10';
  if (!searchObj.page) {
    newSearchObj.page = '1';
  }
  return newSearchObj;
};

export const getPaymentsBody = (size: number, searchObj: SearchParamsPayment) => {
  const newSearchObj = { ...searchObj };
  const types: string[] = [];
  const paymentCustomTypeIds: string[] = [];
  const bases: string[] = [];
  const houses: string[] = [];
  const buildings: string[] = [];
  const currencies: string[] = [];
  const contractStatus: string[] = [];
  const debits: string[] = [];
  const fieldValue: string[] = [];
  const nonResidentialType: string[] = [];

  if (Array.isArray(newSearchObj.nonResidentialType)) {
    newSearchObj.nonResidentialType.forEach(id => {
      nonResidentialType.push(urlDecode(id));
    });
  } else if (newSearchObj.nonResidentialType) {
    nonResidentialType.push(urlDecode(newSearchObj.nonResidentialType));
  }

  if (Array.isArray(newSearchObj.fieldValue)) {
    newSearchObj.fieldValue.forEach(id => {
      fieldValue.push(urlDecode(id));
    });
  } else if (newSearchObj.fieldValue) {
    fieldValue.push(urlDecode(newSearchObj.fieldValue));
  }

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }
  if (Array.isArray(newSearchObj.types)) {
    newSearchObj.types.forEach(id => {
      types.push(id);
    });
  } else if (newSearchObj.types) {
    types.push(newSearchObj.types);
  }
  if (Array.isArray(newSearchObj.paymentCustomTypeIds)) {
    newSearchObj.paymentCustomTypeIds.forEach(id => {
      paymentCustomTypeIds.push(id);
    });
  } else if (newSearchObj.paymentCustomTypeIds) {
    paymentCustomTypeIds.push(newSearchObj.paymentCustomTypeIds);
  }

  if (Array.isArray(newSearchObj.bases)) {
    newSearchObj.bases.forEach(id => {
      bases.push(id);
    });
  } else if (newSearchObj.bases) {
    bases.push(newSearchObj.bases);
  }

  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }

  if (Array.isArray(newSearchObj.currencies)) {
    newSearchObj.currencies.forEach(id => {
      currencies.push(id);
    });
  } else if (newSearchObj.currencies) {
    currencies.push(newSearchObj.currencies);
  }

  if (Array.isArray(newSearchObj.contractStatus)) {
    newSearchObj.contractStatus.forEach(id => {
      contractStatus.push(id);
    });
  } else if (newSearchObj.contractStatus) {
    contractStatus.push(newSearchObj.contractStatus);
  }

  if (Array.isArray(newSearchObj.debits)) {
    newSearchObj.debits.forEach(id => {
      debits.push(id);
    });
  } else if (newSearchObj.debits) {
    debits.push(newSearchObj.debits);
  }

  newSearchObj.nonResidentialType = nonResidentialType.length > 0 ? nonResidentialType : undefined;
  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;
  newSearchObj.fieldValue = fieldValue.length > 0 ? fieldValue : undefined;
  newSearchObj.currencies = currencies.length > 0 ? currencies : undefined;
  newSearchObj.contractStatus = contractStatus.length > 0 ? contractStatus : undefined;
  newSearchObj.debits = debits.length > 0 ? debits : undefined;
  newSearchObj.types = types.length > 0 ? types : undefined;
  // @ts-expect-error
  newSearchObj.paymentCustomTypeIds = paymentCustomTypeIds.length > 0 ? paymentCustomTypeIds : undefined;
  newSearchObj.bases = bases.length > 0 ? bases : undefined;
  if (size) {
    newSearchObj.size = String(size);
  }
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = String(size || 10);
  }

  return newSearchObj;
};

export const getClientsBody = (size: number, searchObj: SearchParamsClient) => {
  const newSearchObj = { ...searchObj };

  newSearchObj.order = undefined;
  const statuses: string[] = [];
  const cities: string[] = [];
  const houses: string[] = [];
  const buildings: string[] = [];

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }

  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }

  if (statuses.length === 0) {
    newSearchObj.statuses = undefined;
  } else newSearchObj.statuses = statuses as any;
  if (Array.isArray(newSearchObj.cities)) {
    newSearchObj.cities.forEach(id => {
      cities.push(id);
    });
  } else if (newSearchObj.cities) {
    cities.push(newSearchObj.cities);
  }
  if (cities.length === 0) {
    newSearchObj.cities = undefined;
  } else newSearchObj.cities = cities as any;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }

  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;

  return newSearchObj;
};

export const getStaffBody = (size: number, searchObj: SearchParamsBroker) => {
  const newSearchObj = { ...searchObj };

  newSearchObj.order = undefined;
  const cities: string[] = [];

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }

  if (Array.isArray(newSearchObj.cities)) {
    newSearchObj.cities.forEach(id => {
      cities.push(id);
    });
  } else if (newSearchObj.cities) {
    cities.push(newSearchObj.cities);
  }
  if (cities.length === 0) {
    newSearchObj.cities = undefined;
  } else newSearchObj.cities = cities as any;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }
  return newSearchObj;
};

export const getDebitsBody = (size: number, searchObj: SearchParamsArrearage) => {
  const newSearchObj = { ...searchObj };
  // const statuses: string[] = []
  const houses: string[] = [];
  const buildings: string[] = [];
  const color: string[] = [];
  const responsibleByIds: string[] = [];
  const currencies: string[] = [];
  const statuses: string[] = [];

  newSearchObj.order = undefined;

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }

  if (Array.isArray(newSearchObj.houses)) {
    newSearchObj.houses.forEach(id => {
      houses.push(id);
    });
  } else if (newSearchObj.houses) {
    houses.push(newSearchObj.houses);
  }
  if (Array.isArray(newSearchObj.responsibleByIds)) {
    newSearchObj.responsibleByIds.forEach(id => {
      responsibleByIds.push(id);
    });
  } else if (newSearchObj.responsibleByIds) {
    responsibleByIds.push(newSearchObj.responsibleByIds);
  }
  if (Array.isArray(newSearchObj.currencies)) {
    newSearchObj.currencies.forEach(id => {
      currencies.push(id);
    });
  } else if (newSearchObj.currencies) {
    currencies.push(newSearchObj.currencies);
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  if (Array.isArray(newSearchObj.buildings)) {
    newSearchObj.buildings.forEach(id => {
      buildings.push(id);
    });
  } else if (newSearchObj.buildings) {
    buildings.push(newSearchObj.buildings);
  }
  if (Array.isArray(newSearchObj.color)) {
    newSearchObj.color.forEach(id => {
      color.push(id);
    });
  } else if (newSearchObj.color) {
    color.push(newSearchObj.color);
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  newSearchObj.buildings = buildings.length > 0 ? buildings : undefined;
  newSearchObj.statuses = statuses.length > 0 ? statuses : undefined;
  newSearchObj.houses = houses.length > 0 ? houses : undefined;
  newSearchObj.color = color.length > 0 ? color : undefined;
  newSearchObj.responsibleByIds = responsibleByIds.length > 0 ? responsibleByIds : undefined;
  newSearchObj.currencies = currencies.length > 0 ? currencies : undefined;
  newSearchObj.statuses = statuses.length > 0 ? statuses : undefined;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }
  return newSearchObj;
};

export const getMessagesBody = (size: number, searchObj: SearchParamsMessage) => {
  const newSearchObj = { ...searchObj };

  newSearchObj.order = undefined;
  const statuses: string[] = [];

  if (Array.isArray(searchObj.order)) {
    let orderObj = {};

    for (let i = 0; i < searchObj.order.length; i++) {
      if (i % 2 === 0) {
        orderObj = {
          ...orderObj,
          [searchObj.order[i]]: searchObj.order[i + 1]
        };
      }
    }
    // @ts-ignore
    newSearchObj.orders = orderObj;
  }
  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  if (statuses.length === 0) {
    newSearchObj.statuses = undefined;
  } else newSearchObj.statuses = statuses as any;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }
  return newSearchObj;
};

export const getCrmBody = (searchObj: SearchParamsCrm, addPage?: boolean) => {
  const newSearchObj = { ...searchObj };
  const pipeStatusIds: string[] = [];
  const tagIds: string[] = [];
  const createdById: string[] = [];
  const responsibleById: string[] = [];
  const reasonsFRIds: [string | null] = [''];
  let fields = {};

  reasonsFRIds.pop();

  if (Array.isArray(newSearchObj.tagIds)) {
    newSearchObj.tagIds.forEach(id => {
      tagIds.push(id);
    });
  } else if (newSearchObj.tagIds) {
    tagIds.push(newSearchObj.tagIds);
  }

  if (Array.isArray(newSearchObj.fields)) {
    newSearchObj.fields.forEach(val => {
      fields = {
        ...fields,
        [urlConvertKeyValue(val)[0]]: urlConvertKeyValue(val)[1]
      };
    });
  } else if (newSearchObj.fields) {
    fields = {
      [urlConvertKeyValue(newSearchObj?.fields)[0]]: urlConvertKeyValue(newSearchObj?.fields)[1]
    };
  }

  if (Array.isArray(newSearchObj?.multiField) && newSearchObj?.multiField) {
    const result = newSearchObj?.multiField.reduce((acc: any, item: any) => {
      const [key, value] = item.split('_'); // Belgi (_ ga) bo'lgan har bir elementni ajratamiz
      const numberKey = Number(key); // Elementni son qismi

      if (!Number.isNaN(numberKey)) {
        // Son bo'lsa
        if (!acc[numberKey]) {
          acc[numberKey] = [];
        }
        acc[numberKey].push(value);
      }
      return acc;
    }, {});

    fields = {
      ...fields,
      [Object.keys(result)[0]]: result[Object.keys(result)[0]]
    };
  } else if (newSearchObj?.multiField) {
    fields = {
      [urlConvertKeyValue(newSearchObj?.multiField as any)[0]]: [urlConvertKeyValue(newSearchObj?.multiField as any)[1]]
    };
  }

  if (Array.isArray(newSearchObj.createdById)) {
    newSearchObj.createdById.forEach(id => {
      createdById.push(id);
    });
  } else if (newSearchObj.createdById) {
    createdById.push(newSearchObj.createdById);
  }
  if (Array.isArray(newSearchObj.responsibleById)) {
    newSearchObj.responsibleById.forEach(id => {
      responsibleById.push(id);
    });
  } else if (newSearchObj.responsibleById) {
    responsibleById.push(newSearchObj.responsibleById);
  }
  if (Array.isArray(newSearchObj.reasonsFRIds)) {
    newSearchObj.reasonsFRIds.forEach(id => {
      if (id === 'null') {
        reasonsFRIds.push(null);
      } else {
        reasonsFRIds.push(id);
      }
    });
  } else if (newSearchObj.reasonsFRIds) {
    if (newSearchObj.reasonsFRIds === 'null') {
      reasonsFRIds.push(null);
    } else {
      reasonsFRIds.push(newSearchObj.reasonsFRIds);
    }
  }

  if (Array.isArray(newSearchObj.pipeStatusIds)) {
    newSearchObj.pipeStatusIds.forEach(id => {
      pipeStatusIds.push(id);
    });
  } else if (newSearchObj.pipeStatusIds) {
    pipeStatusIds.push(newSearchObj.pipeStatusIds);
  }

  if (!searchObj.page && addPage) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }

  newSearchObj.tagIds = tagIds.length > 0 ? tagIds : undefined;
  newSearchObj.createdById = createdById.length > 0 ? createdById : undefined;
  newSearchObj.reasonsFRIds = reasonsFRIds.length > 0 ? reasonsFRIds : undefined;
  newSearchObj.pipeStatusIds = pipeStatusIds.length > 0 ? pipeStatusIds : undefined;
  newSearchObj.responsibleById = responsibleById.length > 0 ? responsibleById : undefined;

  // @ts-ignore
  newSearchObj.fields = Object.keys(fields).length > 0 ? fields : undefined;
  return newSearchObj;
};

export const getTaskBody = (searchObj: SearchParamsTask) => {
  const newSearchObj = { ...searchObj };
  const employeeList: string[] = [];
  const leadTaskTypeIds: string[] = [];
  const createdByIds: string[] = [];
  const pipeStatusIds: string[] = [];

  if (Array.isArray(newSearchObj.employeeList)) {
    newSearchObj.employeeList.forEach(id => {
      employeeList.push(id);
    });
  } else if (newSearchObj.employeeList) {
    employeeList.push(newSearchObj.employeeList);
  }
  if (Array.isArray(newSearchObj.leadTaskTypeIds)) {
    newSearchObj.leadTaskTypeIds.forEach(id => {
      leadTaskTypeIds.push(id);
    });
  } else if (newSearchObj.leadTaskTypeIds) {
    leadTaskTypeIds.push(newSearchObj.leadTaskTypeIds);
  }
  if (Array.isArray(newSearchObj.createdByIds)) {
    newSearchObj.createdByIds.forEach(id => {
      createdByIds.push(id);
    });
  } else if (newSearchObj.createdByIds) {
    createdByIds.push(newSearchObj.createdByIds);
  }

  if (Array.isArray(newSearchObj.pipeStatusIds)) {
    newSearchObj.pipeStatusIds.forEach(id => {
      pipeStatusIds.push(id);
    });
  } else if (newSearchObj.pipeStatusIds) {
    pipeStatusIds.push(newSearchObj.pipeStatusIds);
  }

  newSearchObj.employeeList = employeeList.length > 0 ? employeeList : [];
  newSearchObj.leadTaskTypeIds = leadTaskTypeIds.length > 0 ? leadTaskTypeIds : undefined;
  newSearchObj.createdByIds = createdByIds.length > 0 ? createdByIds : undefined;
  newSearchObj.pipeStatusIds = pipeStatusIds.length > 0 ? pipeStatusIds : undefined;
  return newSearchObj;
};
