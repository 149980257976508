import React from 'react';
import { UpdateCashModel } from '@components/paymentSettings/automaticPayment/CashModel';
import { queryKeys } from '@pages/oneLid/utils/constants/queryKeys';
import { encodeString } from '@utils/helper';
import { IData } from '@utils/models/IData';
import { Marketplace } from '@utils/models/Marketplace';
import { MyHomeConfigModel } from '@utils/models/MyHomeConfigModel';
import { PriceCalculateType } from '@utils/models/PriceCalculateType';
import { PriceChangeType } from '@utils/models/PriceChangeType';
import { message } from 'antd';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { useQueryParam } from '../hooks/useQueryParams';
import { SearchParamsArrearage } from '../pages/arrearage/Arrearage';
import { SearchParamsBroker } from '../pages/broker/Broker';
import { SearchParamsBuilder } from '../pages/builderSettings/BuilderSettings';
import { SearchParamsClient } from '../pages/client/Client';
import { SearchParamsCompanies } from '../pages/companies/Companies';
import { SearchParamsCompanyEmployees } from '../pages/companyEmployees/CompanyEmployees';
import { SearchParamsHome } from '../pages/home/Home';
import { SearchParamsPayment } from '../pages/payment/Payment';
import { SearchParamsEmployee } from '../pages/settingEmployee/SettingEmployee';
import { MainPaths } from '../routing/main/mainPaths';
import { rootPaths } from '../routing/rootPaths';
import { SettingsPaths } from '../routing/settings/settingsPaths';
import i18n from '../service/I18nService';
import { LocalStorage } from '../service/LocalStorage';
import { $api, $apiCustom, BASE_URL } from '../service/RequestService';
import { editAndCreateModal } from '../store/reducers/CompaniesSlice';
import { editAndCreateModal as editAndCreateCompanyEmployee } from '../store/reducers/CompanyEmployeesSlice';
import { setVisible } from '../store/reducers/ContractSlice';
import { setCurrencyCompany } from '../store/reducers/CreateContractSlice';
import { setUser } from '../store/reducers/UserSlice';
import { setWebRTC } from '../store/reducers/WebRTCSlice';
import {
  accountEndpoints,
  activeSessionEndpoints,
  amoCrmEndpoints,
  amoCrmHouseEndpoints,
  authEndpoints,
  autoTaskSettingEndpoints,
  bookingEndpoints,
  branchEndpoints,
  builderEndpoints,
  buildingEndpoints,
  callHistoryEndpoints,
  cardEndpoints,
  cashEndpoints,
  chatMessageEndpoints,
  clientEndpoints,
  clientNoteEndpoints,
  companyAmoCrmConfigEndpoints,
  companyConfigEndpoints,
  companyEndpoints,
  contractEndpoints,
  crmEndpoints,
  crmLeadEndpoints,
  currencyEndpoints,
  debitEndpoints,
  developerEndpoints,
  discountEndpoints,
  downloadpdfEndpoints,
  employeeEndpoints,
  filesEndpoints,
  flatEndpoints,
  houseEndpoints,
  kpiEndpoints,
  leadNoteEndpoints,
  leadTaskEndpoints,
  marketplaceEndpoints,
  merchantEndpoints,
  moiSmsTemplateEndpoints,
  moiZvonkiEndpoints,
  monetaryConfig,
  monetaryEndpoints,
  myHomeConfigEndPoints,
  notificationEndpoints,
  ONLINE_PBX_API,
  onlinePbxEndpoints,
  paymentEndpoints,
  pipeStatusEndpoints,
  playMobileEndpoints,
  PRINT_CHECK_AUTO,
  realtorCongigEndpoints,
  reportConfigEndpoints,
  resetPasswordEndpoints,
  showroomEndpoints,
  smsConfigEndpoints,
  smsTemplateEndpoints,
  staffEndpoints,
  statisticsEndpoints,
  tableColorEndpoints,
  tableEndpoints,
  tagEndpoints,
  taskEndpoints,
  telegramAndWebEndpoints,
  telegramEndpoints,
  uyqurEndpoints,
  widgetEndpoints
} from '../utils/constants/apis';
import { TOKEN_KEY } from '../utils/constants/localStorageKeys';
import { queryNames } from '../utils/constants/queryNames';
import { downloadFile } from '../utils/helper/downloadFile';
import { getClientsBody, getDebitsBody, getFlatsBody } from '../utils/helper/getSearchAndDownloadBodyAllTables';
import { helper } from '../utils/helper/helper';
import { signInSuccess } from '../utils/helper/signInSuccess';
import { urlEncode } from '../utils/helper/urlEncodeDecode';
import { AmoCrmEdit } from '../utils/models/AmoCrm';
import { AmountCancel } from '../utils/models/AmountCancel';
import { Apelsin } from '../utils/models/Apelsin';
import { AutoTaskSetting, AutoTaskSettingPut } from '../utils/models/AutoTaskSetting';
import { BaseEntity } from '../utils/models/BaseEntity';
import { BotChat } from '../utils/models/BotChat';
import { CallHistoryGetRecord } from '../utils/models/CallHistoryGetRecord';
import { CancelOrderBody } from '../utils/models/CancelReasonOrder';
import { CreateCard, CreateCardResponse } from '../utils/models/Card';
import { Cash, SystemTypeCash } from '../utils/models/Cash';
import { ClientBalanceHistoryList, JuridicalPersonCreate, NaturalPersonCreate } from '../utils/models/Client';
import { ClientType } from '../utils/models/ClientType';
import { CompaniesStatus } from '../utils/models/Companies';
import { ContractDelete } from '../utils/models/ContractDelete';
import { CreateContract, MonthlyPayment, MonthlyPaymentStatus } from '../utils/models/CreateContract';
import { CreateOrderJuridical, CreateOrderNatural } from '../utils/models/CreateOrder';
import { CreatePaymentBody } from '../utils/models/CreatePaymentBody';
import { CrmField } from '../utils/models/CrmField';
import { CurrencyItem } from '../utils/models/Currency';
import { DownloadCalculatorPdfBody } from '../utils/models/DownloadCalculatorPdfBody';
import { DownloadPdf } from '../utils/models/DownloadPdf';
import { CompanyEmployeeCreate } from '../utils/models/EmployeeList';
import { FileObject } from '../utils/models/File';
import { FileTemplateLanguage, FileTemplateType } from '../utils/models/FileTemplate';
import { FlatId } from '../utils/models/Flat';
import { HouseSave } from '../utils/models/House';
import { ArgumentKpi, KpiPart } from '../utils/models/Kpi';
import { LeadChatMessage } from '../utils/models/LeadChatMessage';
import { LeadNote } from '../utils/models/LeadNote';
import { Login } from '../utils/models/Login';
import { MonetaryConfig } from '../utils/models/MonetaryConfig';
import { CreateMyCalls, MyCallsSetEmployee, MyMessageTemplate } from '../utils/models/MyCalls';
import { OneLid } from '../utils/models/OneLid';
import { OnlinePbx, OnlinePbxPipeSettingsBody, SetEmployeeOnlinePbx } from '../utils/models/OnlinePbx';
import { PaymentConfig } from '../utils/models/Payment';
import { PaymentChange } from '../utils/models/PaymentChange';
import { PaymentType } from '../utils/models/PaymentType';
import { CreatePlanFactCost } from '../utils/models/PlanFactLeadCost';
import { PlanFactSave } from '../utils/models/PlanFactPipe';
import { PlayMobileMutate } from '../utils/models/PlayMobile';
import { ProfileEdit } from '../utils/models/Profile';
import { Reports } from '../utils/models/Reports';
import { RoleEdit } from '../utils/models/Roles';
import { PayStaff, Staff } from '../utils/models/Staff';
import { Surcharge } from '../utils/models/Surcharge';
import { TableColor } from '../utils/models/TableColor';
import { TableConfigRequest } from '../utils/models/TableConfig';
import { TableKey, TableKeyCustom } from '../utils/models/TableKey';
import { CompleteTask, CreateNoteTask, CreatePaymentTask, CreateSimpleTask, UpdateTask } from '../utils/models/Task';
import { TawType } from '../utils/models/TelegramAndWebProps';
import { Faq, LangBot, SendMessageBodyBot, TelegramBot } from '../utils/models/TelegramBot';
import { User } from '../utils/models/User';
import { UyqurBuilders } from '../utils/models/UyqurBuilders';
import { Widget } from '../utils/models/Widget';

import { baseErrorHandler } from './baseErrorHandler';
import { createClientSuccess } from './createClientSuccess';
import { showMessage } from './showMessage';

// shaxmatka

export function useDownloadPaymentToWord() {
  return useMutation(
    async (data: { id: number; number: string; contractId?: number; bookingId?: number }) => {
      const res = await $api.get(`${contractEndpoints.INDEX + data.contractId}/payment/${data.id}/template`, {
        responseType: 'blob'
      });

      return {
        data: res.data,
        number: data.number
      };
    },
    {
      onSuccess: data => {
        downloadFile(data.data, `payment-${data.number}`, '.docx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

// building
export function useFlatCreate(id: number | undefined) {
  const qc = useQueryClient();

  return useMutation(
    async (data: FlatId[]) => {
      const res = await $api.post(flatEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.FLAT_ALL, id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFlatDelete() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (data: any[]) => {
      const res = await $api.delete(`${flatEndpoints.INDEX}?${data.map(i => i !== undefined && `idList=${i}`).join('&')}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.FLAT_ALL, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useBuilding() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (data: { id: number | undefined; name: string }) => {
      const res = await $api.post(`${buildingEndpoints.INDEX}?houseId=${data.id}&name=${data.name}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.BUILDING_ID, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useBuildingId() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (data: { id: number | undefined; name: string }) => {
      const res = await $api.patch(`${buildingEndpoints.INDEX}?id=${data.id}&name=${data.name}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.BUILDING_ID, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useBuildingDelete() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (id: number | undefined) => {
      const res = await $api.delete(buildingEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.BUILDING_ID, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDiscountPartCreate() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (
      data: [
        {
          paymentPercent: number;
          discountPercent: number;
          months: number;
        }
      ]
    ) => {
      const res = await $api.post(discountEndpoints.INDEX + params.id, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.DISCOUNT_ID, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDiscountEdit(handleCancel: () => void) {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (data: { id: number | undefined; name: string; calculateType: string }) => {
      const res = await $api.patch(discountEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.DISCOUNT_ID, params.id]);
        qc.invalidateQueries([queryNames.DISCOUNT]);
        showMessage(data.message, data.accept);
        handleCancel();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDiscountCreate(handleCancel: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { name: string; calculateType: string }) => {
      const res = await $api.post(discountEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.DISCOUNT]);
        showMessage(data.message, data.accept);
        handleCancel();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDiscountDelete(setVisibleDelete: (visible: boolean) => void) {
  const { params, push } = useQueryParam();
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(discountEndpoints.INDEX + params.id);

      return res.data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([queryNames.DISCOUNT]);
        showMessage(data.message, data.accept);
        push(rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.DISCOUNT);
        setVisibleDelete(false);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSignIn() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: Login) => {
      const res = await $apiCustom.post(authEndpoints.SIGN_IN_DEV, data, { withCredentials: true });

      if (!res.data.accept) return Promise.reject(res.data.message);
      return res.data.data as User;
    },
    {
      onSuccess: async data => {
        LocalStorage.set(TOKEN_KEY, data.accessToken);
        await dispatch(setUser(data));
        signInSuccess(data);
      },
      onError: (res: any) => {
        const message = res?.response?.data?.message;

        const lan = localStorage.getItem('i18nextLng');

        localStorage.setItem('message', JSON.stringify(message[lan as keyof typeof res]));
      }
    }
  );
}

export function useTableConfigMutation(key: TableKey, onClose?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: TableConfigRequest) => {
      const res = await $api.post(tableEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.TABLE, key]);
        showMessage(data?.message, data?.accept);
        onClose && onClose();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateOrder(type: ClientType, setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateOrderJuridical | CreateOrderNatural) => {
      let res;

      if (type === ClientType.NATURAL) {
        res = await $api.post(bookingEndpoints.NATURAL, data);
      } else {
        res = await $api.post(bookingEndpoints.JURIDICAL, data);
      }
      return res.data;
    },

    {
      onSuccess: () => {
        console.log('succes');
        qc.invalidateQueries([queryNames.BOOKED_FLAT_LIST]);
        setOpenSuccessModal(true);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCancelOrder(id: string, closeModal: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CancelOrderBody) => {
      const res = await $api.patch(`${bookingEndpoints.CANCEL}/${id}`, data);

      return res.data;
    },
    {
      onSuccess: data => {
        closeModal();
        qc.invalidateQueries([queryNames.BOOKED, id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndUpdateNaturalPerson(orderId?: string) {
  const qc = useQueryClient();
  const { push, location } = useQueryParam();

  return useMutation(
    async (data: NaturalPersonCreate) => {
      let res;

      if (data?.id) {
        res = await $api.patch(clientEndpoints.NATURAL, data);
      } else {
        res = await $api.post(clientEndpoints.NATURAL, data);
      }
      return res.data;
    },
    {
      onSuccess: data => {
        createClientSuccess(data, qc, orderId, push, location.pathname);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndUpdateJuridicalPerson(orderId?: string) {
  const qc = useQueryClient();
  const { push, location } = useQueryParam();

  return useMutation(
    async (data: JuridicalPersonCreate) => {
      let res;

      if (data?.id) {
        res = await $api.patch(clientEndpoints.JURIDICAL, data);
      } else {
        res = await $api.post(clientEndpoints.JURIDICAL, data);
      }
      return res.data;
    },
    {
      onSuccess: data => {
        createClientSuccess(data, qc, orderId, push, location.pathname);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateContract() {
  const dispatch = useAppDispatch();
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateContract) => {
      const res = await $api.post(contractEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setVisible({
            visible: true,
            contractId: data?.data?.id,
            contractNumber: data?.data?.number
          })
        );
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUploadFile(getFile: (data: FileObject[]) => void, isShowMessage = true) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(filesEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        getFile(data.data);
        isShowMessage && showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUploadFileDeveloper(getFile?: (data: FileObject[]) => void) {
  return useMutation(
    async (data: FormData) => {
      const res = await $api.post(filesEndpoints.DEVELOPER, data);

      return res.data;
    },
    {
      onSuccess: data => {
        if (getFile) getFile(data.data);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteFile(success: () => void) {
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(filesEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        success();
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePaymentChange() {
  const { params } = useQueryParam();
  const qc = useQueryClient();

  return useMutation(
    async (data: PaymentChange) => {
      const res = await $api.patch(contractEndpoints.AMOUNT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_AMOUNT, params.id]);
        qc.invalidateQueries([queryNames.CONTRACT_ID, params.id]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useContractAmountCancel() {
  const { params } = useQueryParam();
  const qc = useQueryClient();

  return useMutation(
    async (data: AmountCancel) => {
      const res = await $api.patch(contractEndpoints.CANCEL, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_AMOUNT, params.id]);
        qc.invalidateQueries([queryNames.CONTRACT_ID, params.id]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useContractDelete() {
  const { params } = useQueryParam();
  const qc = useQueryClient();

  return useMutation(
    async (data: ContractDelete) => {
      const res = await $api.delete(contractEndpoints.INDEX, { data });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_ID, params.id]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeTableMonthlyPayments(id: number, number: string) {
  const { push } = useQueryParam();

  return useMutation(
    async (data: { contractId: number; cancelText: string; monthlyPaymentPartDtos: MonthlyPayment[] }) => {
      const res = await $api.patch(contractEndpoints.MONTHLY_PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        push(`${rootPaths.MAIN + MainPaths.CONTRACT}/${id}/${number}`);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreatePayment(id: string, close: (id?: number, number?: string) => void) {
  const qc = useQueryClient();
  const url = `${contractEndpoints.INDEX + id}/payment`;
  const downloadCheck = useDownloadPaymentToWord();

  return useMutation(
    async (data: CreatePaymentBody) => {
      const res = await $api.post(url, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_DATA_FOR_CREATE_PAYMENT, id]);
        qc.invalidateQueries([queryNames.PAYMENT, id]);
        qc.invalidateQueries([queryNames.CONTRACT_MOTHLY_PAYMENT, id, MonthlyPaymentStatus.ACTIVE]);
        qc.invalidateQueries([queryNames.PAYMENT_NUMBER]);
        qc.invalidateQueries([queryNames.CONTRACT_ID]);
        qc.invalidateQueries([queryNames.PAYMENT]);
        qc.invalidateQueries([queryNames.CONTRACT_NUMBER]);
        close(data?.data?.id, data?.data?.number);
        downloadCheck.mutate({
          contractId: Number(id),
          id: data?.data?.id,
          number: data?.data?.number
        });
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteOrder(paymentIds: number[], closeModal: () => void, contractId: string, searchObj?: SearchParamsPayment) {
  const qc = useQueryClient();

  return useMutation(
    async (cancelText: string) => {
      const res = await $api.delete(contractEndpoints.PAYMENT, {
        data: {
          cancelText,
          paymentIds
        }
      });

      return res.data;
    },
    {
      onSuccess: data => {
        closeModal();

        qc.invalidateQueries([queryNames.CONTRACT_DATA_FOR_CREATE_PAYMENT, contractId]);

        qc.invalidateQueries([queryNames.PAYMENT, contractId]);
        qc.invalidateQueries([queryNames.PAYMENT, 'sum']);
        qc.invalidateQueries([queryNames.CONTRACT_ID, contractId]);
        qc.invalidateQueries([queryNames.CONTRACT_MOTHLY_PAYMENT, contractId, MonthlyPaymentStatus.ACTIVE]);
        if (searchObj) {
          qc.invalidateQueries([queryNames.PAYMENT, searchObj.page, JSON.stringify(searchObj)]);
        }
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDebitCreate(clientId: string, type: 'out' | 'in', close: (data: ClientBalanceHistoryList) => void) {
  const qc = useQueryClient();
  const url = `${clientEndpoints.INDEX}${clientId}/payment/${type}`;

  return useMutation(
    async (data: { amount: number; type: PaymentType; currencyId: number; balanceCurrencyId: number; note?: string }) => {
      const res = await $api.post(url, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        close(data.data as ClientBalanceHistoryList);
        qc.invalidateQueries([queryNames.CLIENT_BALANCE_LIST, clientId]);
        qc.invalidateQueries([queryNames.CLIENT_VIEW, clientId]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useEmployee(handleCancel: () => void, searchParamsEmployee: SearchParamsEmployee) {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      fullName: string;
      phone: string;
      phoneCode: string;
      username: string;
      password: string;
      districtId: number;
      address: string;
      roleId: number;
      avatarId: number | null;
      expiry: string;
      branchId: number | null;
      roleBranches?: Array<{
        roleId: number;
        branchId?: number | null;
      }> | null;
    }) => {
      const res = await $api.post(employeeEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.EMPLOYEE_LIST, searchParamsEmployee.size || '10', JSON.stringify(searchParamsEmployee)]);
        handleCancel();
      },
      onError: baseErrorHandler
    }
  );
}

export function useEmployeeEdit(id: number, handleCancel: () => void, searchParamsEmployee: SearchParamsEmployee) {
  const qc = useQueryClient();

  const newSearchObj = { ...searchParamsEmployee };
  const statuses: string[] = [];
  const roles: string[] = [];

  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  if (Array.isArray(newSearchObj.roles)) {
    newSearchObj.roles.forEach(id => {
      roles.push(id);
    });
  } else if (newSearchObj.roles) {
    roles.push(newSearchObj.roles);
  }
  newSearchObj.statuses = statuses.length > 0 ? (statuses as any) : undefined;
  newSearchObj.roles = roles.length > 0 ? (roles as any) : undefined;
  if (!searchParamsEmployee.page) {
    newSearchObj.page = '1';
    newSearchObj.size = '10';
  }

  return useMutation(
    async (data: {
      fullName: string;
      phone: string;
      phoneCode: string;
      username: string;
      password?: string;
      districtId: number;
      address: string;
      roleId: number;
      status: string;
      avatarId: number | null;
      expiry: string;
      branchId: number | null;
      roleBranches?: Array<{
        roleId: number;
        branchId?: number | null;
      }> | null;
    }) => {
      const res = await $api.patch(employeeEndpoints.INDEX + id, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.EMPLOYEE_LIST, JSON.stringify(newSearchObj)]);
        handleCancel();
      },
      onError: baseErrorHandler
    }
  );
}
export function useSaveHouse(search: string, close: () => void) {
  const { notSalePageSize, salePageSize } = useAppSelector(state => state.houseConfigReducer);
  const qc = useQueryClient();

  return useMutation(
    async (data: HouseSave) => {
      if (data.id) {
        const res = await $api.patch(houseEndpoints.INDEX, data);

        return res.data;
      }
      const res = await $api.post(houseEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, notSalePageSize, search, false]);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, salePageSize, search, true]);
        close();
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeSaleStatusHouse(search: string, sale: boolean, houseId: number, buildingIds?: number[]) {
  const { notSalePageSize, salePageSize } = useAppSelector(state => state.houseConfigReducer);
  const qc = useQueryClient();
  const buildingIdsArr = buildingIds?.map(item => `buildingIds=${item}`);

  return useMutation(
    async () => {
      const res = await $api.post(`${houseEndpoints.ON_SALE}?houseID=${houseId}&onSaleStatus=${sale}&${buildingIdsArr?.join('&')}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, notSalePageSize, search, false]);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, salePageSize, search, true]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteHouse(search: string, close: () => void, id: number) {
  const { notSalePageSize, salePageSize } = useAppSelector(state => state.houseConfigReducer);
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(houseEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, notSalePageSize, search, false]);
        qc.invalidateQueries([queryNames.HOUSE_CONFIG_LIST, 1, salePageSize, search, true]);
        close();
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateSmsTemplate(handleSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { type: string; text: string }) => {
      const res = await $api.patch(smsTemplateEndpoints.INDEX + data.type, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MESSAGE_TEMPLATE]);
        handleSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateSmsConfig(type: string) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { active: boolean; interval: number | null; sendToAll?: boolean }) => {
      const res = await $api.patch(smsConfigEndpoints.INDEX + type, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MESSAGE_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSaveCompanyLogo() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.post(`${companyConfigEndpoints.COMPANY_LOGO}?fileID=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.COMPANY_LOGO]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSaveShowroomColor() {
  const qc = useQueryClient();

  return useMutation(
    async (color: string) => {
      const res = await $api.post(companyConfigEndpoints.SYSTEM_COLOR, {
        color
      });

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.SHOWROOM_COLOR]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateBuilder(searchObj: SearchParamsBuilder) {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      name: string;
      phone: string;
      account: string;
      mfo: string;
      inn: string;
      oked: string;
      bank: string;
      director: string;
      district: number;
      address: string;
      callCenterPhone: string;
    }) => {
      const res = await $api.post(builderEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.BUILDER_SETTINGS, JSON.stringify(searchObj)]);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePlayMobileConfMutation() {
  const qc = useQueryClient();

  return useMutation(
    async (data: PlayMobileMutate) => {
      const res = await $api.post(playMobileEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.PLAY_MOBILE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePlayMobileConfOriginationMutation() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id?: number | undefined; name: string; houses: number[] | undefined }) => {
      const res = await $api.post(playMobileEndpoints.ORIGINATOR, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.PLAY_MOBILE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePlayMobileConfOriginationDelete(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${playMobileEndpoints.ORIGINATOR}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.PLAY_MOBILE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePlayMobileConfDelete() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(playMobileEndpoints.INDEX);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.PLAY_MOBILE]);
        showMessage(data.message, data.accept);
      }
    }
  );
}

/// / downloadExcel
export function useDownloadFlatsToExcel(size: number, searchObj: SearchParamsHome) {
  return useMutation(
    async () => {
      const res = await $api.post(`${flatEndpoints.FILTER_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getFlatsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useArrearageOneDay(size: number, searchObj: SearchParamsArrearage, onClose: () => void) {
  return useMutation(
    async (date: string) => {
      const res = await $api.post(
        `${debitEndpoints.IN_DATE_EXCEL}?lang=${i18n.language?.toUpperCase()}`,
        {
          ...getDebitsBody(size, searchObj),
          date
        },
        {
          responseType: 'blob'
        }
      );

      return {
        data: res.data,
        date
      };
    },
    {
      onSuccess: data => {
        // downloadFile(data.data, `Qarzdorlik-${data.date}`, 'xlsx');
        onClose();
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadFilesByLink(url: string, name: string, type: string) {
  return useMutation(
    async (dataUrl: string | void) => {
      const res = await $api.get(dataUrl || url, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, name, type);
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useUpdateContractFile() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id?: number; language: string; fileID: number; logic?: string; type: string; customTempId?: number }) => {
      const res = await $api.put(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/update?documentType=${data.type}`, {
        id: data.id,
        language: data.language,
        fileID: data?.fileID,
        logic: data?.logic
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.DOCUMENT_TEMPLATE]);
      }
    }
  );
}

export function useDownloadClientsToExcel(size: number, searchObj: SearchParamsClient) {
  return useMutation(
    async () => {
      const res = await $api.post(`${clientEndpoints.FILTER_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getClientsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Mijozlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadTableMonthlyPayments(contractId: number) {
  const url = `${contractEndpoints.INDEX}${contractId}/monthly-payment/excel`;

  return useMutation(
    async () => {
      const res = await $api.get(url, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `To'lov-jadvali-${contractId}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useUpdateCompanyConfigPatters(type: 'contract' | 'payment', handleSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (pattern: string) => {
      if (type === 'contract') {
        const res = await $api.post(companyConfigEndpoints.CONTRACT_NUMBER_PATTERN, { pattern });

        return res.data;
      }
      const res = await $api.post(companyConfigEndpoints.PAYMENT_NUMBER_PATTERN, { pattern });

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.COMPANY_CONFIG_NUMERATION, type]);
        handleSuccess();
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadPaymentsOneContractToExcel(id: string, number: string) {
  return useMutation(
    async () => {
      const res = await $api.get(`${contractEndpoints.INDEX + id}/payment/excel`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `${number}-shartnoma-to'lovlari`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useBuilderEdit(searchObj: SearchParamsBuilder) {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      id: number;
      name: string;
      phone: string;
      account: string;
      mfo: string;
      inn: string;
      oked: string;
      bank: string;
      director: string;
      district: number;
      address: string;
      callCenterPhone?: string | null;
    }) => {
      const res = await $api.patch(builderEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.BUILDER_SETTINGS, JSON.stringify(searchObj)]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadDocumentSample(type: FileTemplateType) {
  return useMutation(
    async () => {
      const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${type}/sample`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `${type}-sample`, '.docx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDeleteDocumentSample(id: number, type: FileTemplateType) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${type}?templateID=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.DOCUMENT_TEMPLATE, type]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useTestTemplate(id: number, type: FileTemplateType) {
  return useMutation(
    async () => {
      const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${type}/test?templateID=${id}`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, 'test-template', '.docx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadClientBalance(id: string, name: string) {
  return useMutation(
    async () => {
      const res = await $api.get(`${clientEndpoints.INDEX + id}/payment/excel`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `${name}-balance`, '.xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadContractToWord(id: number | null, number: string | null, onSuccess?: () => void, customId: number | null = null) {
  return useMutation(
    async (lang: FileTemplateLanguage | undefined) => {
      if (customId !== null) {
        const res = await $api.get(`${contractEndpoints.INDEX + id}/template?lang=${lang}&customId=${customId}`, {
          responseType: 'blob'
        });

        return res.data;
      }
      const res = await $api.get(`${contractEndpoints.INDEX + id}/template?lang=${lang}`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `contract-${number}`, '.docx');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function usePrintCheckAuto() {
  return useMutation(async (data: { id: number; contractId: number }) => {
    const token = LocalStorage.get<string>(TOKEN_KEY);
    const res = await axios.post(PRINT_CHECK_AUTO, {
      url: `${BASE_URL + contractEndpoints.INDEX + data.contractId}/payment/${data.id}/template`,
      token: `Bearer ${token}`
    });

    return res.data;
  });
}

export function useDownloadClientPaymentToWord() {
  return useMutation(
    async (data: { id: number; date: string }) => {
      const res = await $api.get(`${clientEndpoints.CLIENT_PAYMENT_TEMPLATE}/${data.id}`, {
        responseType: 'blob'
      });

      return {
        data: res.data,
        date: data.date
      };
    },
    {
      onSuccess: data => {
        downloadFile(data.data, `client-payment-${data.date}`, '.docx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useAutoPrintClientBalanceCheck() {
  return useMutation(async (data: { id: number }) => {
    const token = LocalStorage.get<string>(TOKEN_KEY);
    const res = await axios.post(PRINT_CHECK_AUTO, {
      url: `${BASE_URL + clientEndpoints.CLIENT_PAYMENT_TEMPLATE}/${data.id}`,
      token: `Bearer ${token}`
    });

    return res.data;
  });
}

export function useCreateBranch(setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: number | null }>>) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { name: string; districtID: number; address: string }) => {
      const res = await $api.post(branchEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries(queryNames.BRANCH_SETTING);
        setVisible({ visible: false, id: null });
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateBranch(setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: number | null }>>) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id: number; name: string; districtID: number; address: string }) => {
      const res = await $api.patch(branchEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries(queryNames.BRANCH_SETTING);
        setVisible({ visible: false, id: null });
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndEditCompanies(id: number, searchObj: SearchParamsCompanies) {
  const qc = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: { name: string; phone: string; director: string; status: CompaniesStatus; expiry: string; flatsLimit?: number | null }) => {
      if (id === 0) {
        const res = await $api.post(companyEndpoints.MAIN, data);

        return res.data;
      }
      const res = await $api.patch(companyEndpoints.MAIN + id, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.COMPANIES_LIST, searchObj.page, JSON.stringify(searchObj)]);
        dispatch(editAndCreateModal({ id: 0, open: false }));
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndEditCompanyEmployees(employeeId: number, companyId: string, searchObj: SearchParamsCompanyEmployees) {
  const qc = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: CompanyEmployeeCreate) => {
      if (employeeId === 0) {
        const res = await $api.post(developerEndpoints.EMPLOYEE, {
          ...data,
          companyId
        });

        return res.data;
      }
      const res = await $api.patch(`${developerEndpoints.EMPLOYEE}/${employeeId}`, {
        ...data,
        companyId,
        password: data.password === '' ? undefined : data.password
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.ONE_COMPANY_EMPLOYEES, companyId, JSON.stringify(searchObj)]);
        dispatch(editAndCreateCompanyEmployee({ id: 0, open: false }));
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAndEditRole(companyId: string, onSuccess: () => void, roleId?: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: RoleEdit) => {
      if (!roleId) {
        const res = await $api.post(authEndpoints.ROLE, {
          ...data,
          companyId: Number(companyId)
        });

        return res.data;
      }
      const res = await $api.patch(`${authEndpoints.ROLE}/${roleId}`, {
        ...data,
        companyId: Number(companyId)
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess();
        qc.invalidateQueries([queryNames.COMPANY_ROLE_PERMISSION, companyId]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeRolePermission() {
  return useMutation(
    async (data: { roleId: number; permission: string; active: boolean }) => {
      const res = await $api.patch(`${authEndpoints.ROLE}/${data.roleId}/permission/${data.permission}?active=${data.active}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadCalculatorTable() {
  return useMutation(
    async (data: DownloadCalculatorPdfBody[]) => {
      const res = await $api.post(showroomEndpoints.PDF_PLAN_TABLE, data, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `table-flat`, 'pdf');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadCalculatorInfo() {
  return useMutation(
    async (data: DownloadCalculatorPdfBody[]) => {
      const res = await $api.post(showroomEndpoints.PDF_PLAN_IMAGE, data, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `flats-info`, 'pdf');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useCashCreate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: Cash) => {
      const res = await $api.post(cashEndpoints.INDEX, {
        ...data,
        status: true
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PAYME_CASH]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCashChangeStatus() {
  const qc = useQueryClient();

  return useMutation(
    async (data: UpdateCashModel) => {
      const res = await $api.put(cashEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PAYME_CASH]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCashDelete(id: number | undefined) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${cashEndpoints.INDEX}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PAYME_CASH]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCard(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (data: CreateCard) => {
      const res = await $api.post(cardEndpoints.PAYME, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCardUzcard(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (data: CreateCard) => {
      const res = await $api.post(cardEndpoints.MY_UZCARD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCardResentCode(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (cardId: number) => {
      const res = await $api.put(`${cardEndpoints.PAYME_VERIFY}?cardId=${cardId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCardResentCodeUzcard(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (cardId: number) => {
      const res = await $api.put(`${cardEndpoints.MY_UZCARD_VERIFY}?cardId=${cardId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useActivateCard(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (cardId: number) => {
      const res = await $api.put(`${cardEndpoints.PAYME_ACTIVATE}?cardId=${cardId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useActivateCardUzcard(onSuccess: (data: CreateCardResponse) => void) {
  return useMutation(
    async (cardId: number) => {
      const res = await $api.put(`${cardEndpoints.MY_UZCARD_ACTIVATE}?cardId=${cardId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCardVerify(contractId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { cardId: number; code: string }) => {
      const res = await $api.post(`${cardEndpoints.PAYME_VERIFY}?cardId=${data.cardId}&code=${data.code}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_CARDS, contractId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCardVerifyUzcard(contractId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { cardId: number; code: string; session: number }) => {
      const res = await $api.post(`${cardEndpoints.MY_UZCARD_VERIFY}?cardId=${data.cardId}&code=${data.code}&session=${data.session}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_CARDS, contractId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteCard(contractId: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { cardId: number; type: SystemTypeCash }) => {
      let url = `${cardEndpoints.MY_UZCARD}?cardId=${data.cardId}`;

      if (data.type === SystemTypeCash.PAYME) {
        url = `${cardEndpoints.PAYME}?cardId=${data.cardId}`;
      }
      const res = await $api.delete(url);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_CARDS, contractId]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStepCard(contractId: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { cardId: number; step: number }) => {
      const res = await $api.put(`${cardEndpoints.STEP}?cardId=${data.cardId}&step=${data.step}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_CARDS, contractId]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStyleCard(contractId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { cardId: number; style: number }) => {
      const res = await $api.put(`${cardEndpoints.STYLE}?cardId=${data.cardId}&style=${data.style}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CONTRACT_CARDS, contractId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useSurchargeCreate() {
  return useMutation(
    async (data: Surcharge) => {
      const res = await $api.post(companyEndpoints.SURCHARGE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSurchargeDelete() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(companyEndpoints.SURCHARGE);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.SURCHARGE]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateTelegramBot() {
  const qc = useQueryClient();

  return useMutation(
    async (data: TelegramBot) => {
      const res = await $api.post(telegramEndpoints.BOT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_BOT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useStartTelegramBot() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.put(telegramEndpoints.BOT_START);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_BOT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useStopTelegramBot() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.put(telegramEndpoints.BOT_STOP);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_BOT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateFaq(onSuccess: () => void, lang: LangBot) {
  const qc = useQueryClient();

  return useMutation(
    async (data: Faq) => {
      const res = await $api.post(telegramEndpoints.BOT_FAQ, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_FAQ, lang]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteFaq(id: number, lang: LangBot) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${telegramEndpoints.BOT_FAQ}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_FAQ, lang]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateFaq(lang: LangBot) {
  const qc = useQueryClient();

  return useMutation(
    async (data: Faq) => {
      const res = await $api.put(telegramEndpoints.BOT_FAQ, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_FAQ, lang]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateAmoCrm(onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: AmoCrmEdit) => {
      const res = await $api.post(amoCrmEndpoints.AUTH_CREATE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.AMO_CRM_CONFIG]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useEditAmoCrm(onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: AmoCrmEdit) => {
      const res = await $api.put(amoCrmEndpoints.AUTH_EDIT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.AMO_CRM_CONFIG]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStatusAmoCrm() {
  const qc = useQueryClient();

  return useMutation(
    async (data: boolean) => {
      const res = await $api.get(`${amoCrmEndpoints.AUTH_CHANGE_STATUS}?changeStatus=${data}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.AMO_CRM_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStatusUrlAmoCrm() {
  const qc = useQueryClient();

  return useMutation(
    async (data: boolean) => {
      const res = await $api.get(`${companyAmoCrmConfigEndpoints.LEAD_URL}?start=${data}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.AMO_CRM_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStatusNoteAmoCrm() {
  const qc = useQueryClient();

  return useMutation(
    async (data: boolean) => {
      const res = await $api.get(`${companyAmoCrmConfigEndpoints.LEAD_TEXT_AREA}?start=${data}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.AMO_CRM_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateRemotePayment(onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: Apelsin) => {
      const res = await $api.post(merchantEndpoints.APELSIN, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MERCHANT_APELSIN]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteRemotePayment() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${merchantEndpoints.APELSIN}?apelsinId=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MERCHANT_APELSIN]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateRemotePayment() {
  const qc = useQueryClient();

  return useMutation(
    async (data: Apelsin) => {
      const res = await $api.put(merchantEndpoints.APELSIN, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MERCHANT_APELSIN]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateStaff(size: number, searchObj: SearchParamsBroker, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: Staff) => {
      const res = await $api.post(staffEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.STAFF, searchObj.page, JSON.stringify(searchObj)]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateStaff(size: number, searchObj: SearchParamsBroker, onSuccess: () => void, id: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: Staff) => {
      const res = await $api.patch(staffEndpoints.INDEX + id, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.STAFF, searchObj.page, JSON.stringify(searchObj)]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function usePayStaff(id: number, onSuccess: () => void, date: string[]) {
  const qc = useQueryClient();

  return useMutation(
    async (data: PayStaff) => {
      const res = await $api.post(`${staffEndpoints.INDEX + id}/pay`, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.STAFF_INFORMATION, id, date[0], date[1]]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStatusKpi(active: boolean, id: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.post(`${kpiEndpoints.CHANGES_STATUS}/${id}?active=${active}`);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.KPI_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeStatusArgumentKpi(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async (argumentKpi: ArgumentKpi) => {
      const res = await $api.post(kpiEndpoints.EDIT_ARGUMENT, {
        kpiId: id,
        argumentKpi
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.KPI_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useEditKpiPart(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: KpiPart) => {
      const res = await $api.post(kpiEndpoints.CREATE_KPI_PART, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.KPI_CONFIG]);
        if (onSuccess) onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useEditKpiPartConst() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { kpiPartId: number; amount: number; currencyId?: number }) => {
      const res = await $api.post(
        `${kpiEndpoints.EDIT_CONSTANT_BONUS_AMOUNT}/${data.kpiPartId}?constantBonusAmount=${data.amount}${data.currencyId ? `&currencyId=${data.currencyId}` : ''}`
      );

      // console.log('TFFFF', data);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.KPI_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteKpiPart(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${kpiEndpoints.DELETE}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.KPI_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

// edit profile
export function useEditProfile(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: ProfileEdit) => {
      const res = await $api.patch(accountEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.PROFILE]);
        if (onSuccess) onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

// create payment task
export function useCreatePaymentTask(contractId: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreatePaymentTask) => {
      const res = await $api.post(taskEndpoints.PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.UNCOMPLETED_TASK, contractId]);
        qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

// create simple task
export function useCreateSimpleTask(contractId: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateSimpleTask) => {
      const res = await $api.post(taskEndpoints.SIMPLE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.UNCOMPLETED_TASK, contractId]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

// create note task
export function useCreateNoteTask(clientId: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateNoteTask) => {
      const res = await $api.post(taskEndpoints.NOTE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.COMPLETED_TASK, clientId]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

// create note task
export function useCompleteTask(contractId: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CompleteTask) => {
      const res = await $api.post(taskEndpoints.CLOSE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        console.log(contractId);
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.UNCOMPLETED_TASK, contractId]);
        qc.invalidateQueries([queryNames.COMPLETED_TASK, contractId]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteTask(contractId?: number) {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${taskEndpoints.INDEX}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.UNCOMPLETED_TASK, contractId]);
        qc.invalidateQueries([queryNames.COMPLETED_TASK, contractId]);
        qc.invalidateQueries([queryKeys.CLIENT_EVENT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateTask(contractId: number, id: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: UpdateTask) => {
      const res = await $api.patch(`${taskEndpoints.INDEX}${id}`, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.UNCOMPLETED_TASK, contractId]);
        qc.invalidateQueries([queryNames.COMPLETED_TASK, contractId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

// table color
export function useTableColor() {
  return useMutation(
    async (data: TableColor) => {
      const res = await $api.post(tableColorEndpoints.INDEX, data);

      return res.data;
    },
    {
      onError: baseErrorHandler
    }
  );
}

// auto-task-setting
export function useAutoTaskCreate(onSuccess: (e: AutoTaskSetting) => void) {
  return useMutation(
    async () => {
      const res = await $api.get(autoTaskSettingEndpoints.INDEX);

      return res.data;
    },
    {
      onSuccess: data => {
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useAutoTaskUpdate() {
  return useMutation(
    async (data: AutoTaskSettingPut) => {
      const res = await $api.put(autoTaskSettingEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadChangeStep(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { leadId: number; statusId: number; updateTimeDate: string; newLead?: boolean; reasonsId?: number | null; autoClosedLeadTask?: boolean }) => {
      const res = await $api.post(crmLeadEndpoints.CHANGE_STEP, data);

      return res.data;
    },
    {
      onSuccess: () => {
        // showMessage(data.message, data.accept)
        qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadSave(onSuccess?: (val: OneLid) => void, id?: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      balance?: number;
      pipeStatusId: number;
      name: string | null;
      responsibleById?: number;
      tagIds?: number[];
      contacts: {
        name?: string;
        avatarId?: number;
        id?: number;
        fields: {
          id: number;
          value: string;
        }[];
        phones?: string[] | null;
      }[];

      fields:
      | {
        id?: number;
        valueType?: 'array';
        valueList?: Array<string>;
      }
      | { id?: number; value?: string; valueType?: 'string' }[];
      id?: number;
      reasonsId?: number | null;
      autoClosedLeadTask?: boolean;
    }) => {
      const res = await $api.post(crmLeadEndpoints.SAVE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
        onSuccess && onSuccess(data.data);
        id && qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePipeStatus() {
  const qc = useQueryClient();

  return useMutation(
    async (
      data: {
        name: string;
        orders: number;
        color: string;
        pipeId: number;
        id?: number;
      }[]
    ) => {
      const res = await $api.post(pipeStatusEndpoints.SAVE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PIPE_ALL]);
        qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
      }
    }
  );
}

export function useFieldsSave(id?: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CrmField[]) => {
      const res = await $api.post(crmEndpoints.FIELD, {
        companyLeadFields: data
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CRM_FIELDS]);
        if (id) {
          qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
          qc.invalidateQueries([queryNames.CRM_ONE_LID, id]);
        }
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateTag(onSuccess?: (val: BaseEntity) => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: string) => {
      const res = await $api.post(tagEndpoints.INDEX, {
        name: data
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CRM_TAGS]);
        onSuccess && onSuccess(data.data as BaseEntity);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateLeadTask(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      leadTaskTypeId: number;
      assignedById: number;
      deadlineStart: string;
      deadlineFinish: string;
      leadId?: number;
      text: string;
      contractId?: number;
      clientId?: number;
      id?: number;
    }) => {
      const res = await $api.post(leadTaskEndpoints.SAVE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED]);
        qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
        // qc.invalidateQueries([queryNames.TASK_FILTER_CRM])
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateLeadNote(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: LeadNote) => {
      const res = await $api.post(leadNoteEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateClientNote(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { text: string; clientId: number; id?: number; pin?: boolean; fileIds: number[] | null }) => {
      const res = await $api.post(clientNoteEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
        qc.invalidateQueries([queryKeys.CLIENT_EVENT, id]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadChatMessage(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: LeadChatMessage) => {
      const res = await $api.post(chatMessageEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, id]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteLead() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(crmLeadEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PIPE_ALL]);
        qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCrmFacebookLogOut() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(crmEndpoints.FACEBOOK_ACCOUNT);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FACEBOOK_ACCOUNT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCrmFacebookRedirectUrl(success: (data: string) => void) {
  return useMutation(
    async (id: number) => {
      const res = await $api.get(`${crmEndpoints.FACEBOOK_REDIRECT_URL}?pipeId=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        success(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useTelegramAndWebSubmit() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id: number; houseId: number | null; pipeId: number; status: boolean; tawType: TawType }) => {
      const res = await $api.post(telegramAndWebEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.TELEGRAM_AND_WEB]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFacebookPagePipe() {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      pageId: number;
      pipeId: number;
      sourceType: string;
      messenger: string;

      id?: number;
    }) => {
      const res = await $api.post(crmEndpoints.FACEBOOK_APP_PIPE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FACEBOOK_PAGE_PIPE]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFacebookPagePipeDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${crmEndpoints.FACEBOOK_PAGE_PIPE}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FACEBOOK_PAGE_PIPE]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFacebookLeadFormPipeStatus() {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      id?: number;
      leadFormId: string;
      leadFormName: string;
      leadFormLocale: string;
      leadFormStatus: string;
      tags: {
        id: number;
        name: string;
      }[];
      pageId: number;
      pipeStatusId: number;
      questions: {
        id?: number;
        fieldId: number | string;
        fieldName: string;
        fieldKey: string;
        lfkey: string;
        lflabel: string;
        lftype: string;
        lfid: string;
      }[];
    }) => {
      const res = await $api.post(crmEndpoints.FACEBOOK_LEAD_FORM_PIPE_STATUS, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FACEBOOK_PAGE_PIPE]);
        qc.invalidateQueries([queryNames.FACEBOOK_LEAD_FORM]);
        qc.invalidateQueries([queryNames.FACEBOOK_LEAD_FORM_ONE]);
        qc.invalidateQueries([queryNames.FACEBOOK_PIPE_STATUS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFacebookLeadFormPipeStatusDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${crmEndpoints.FACEBOOK_LEAD_FORM}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FACEBOOK_PAGE_PIPE]);
        qc.invalidateQueries([queryNames.FACEBOOK_LEAD_FORM]);
        qc.invalidateQueries([queryNames.FACEBOOK_LEAD_FORM_ONE]);
        qc.invalidateQueries([queryNames.FACEBOOK_PIPE_STATUS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateMyCalls() {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateMyCalls) => {
      const res = await $api.post(moiZvonkiEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MY_CALLS_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteMyCalls(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(moiZvonkiEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MY_CALLS_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMyCallsSetEmployee() {
  const qc = useQueryClient();

  return useMutation(
    async (data: MyCallsSetEmployee) => {
      const res = await $api.post(moiZvonkiEndpoints.SET_EMPLOYEE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MY_CALLS_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMyMessageTemplateSave(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: MyMessageTemplate) => {
      const res = await $api.post(moiSmsTemplateEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.MY_MESSAGE_TEMPLATE]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useMyMessageTemplateDelete(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: number) => {
      const res = await $api.delete(moiSmsTemplateEndpoints.INDEX + data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.MY_MESSAGE_TEMPLATE]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useNotificationCreate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id?: number; title: string; description: string; attachment: string; attachmentThumbnail: string }) => {
      const res = await $api.post(notificationEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.NOTIFICATION]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useNotificationMeReadAll() {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.get(notificationEndpoints.ME_READ_ALL);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.NOTIFICATION_ME_COUNT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateOnlinePbx() {
  const qc = useQueryClient();

  return useMutation(
    async (data: OnlinePbx) => {
      const res = await $api.post(onlinePbxEndpoints.AUTH, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.ONLINE_PBX]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteOnlinePbx(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(onlinePbxEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.ONLINE_PBX]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSetEmployeeOnlinePbx(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async (data: SetEmployeeOnlinePbx) => {
      const res = await $api.post(onlinePbxEndpoints.SET_EMPLOYEE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.ONLINE_PBX_EMPLOYEE, id]);
        qc.invalidateQueries([queryNames.ONLINE_PBX_HISTORY_CALL]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeletePipeOnlinePbx(id: number) {
  const qc = useQueryClient();

  return useMutation(
    async (pipeSettingsId: number) => {
      const res = await $api.delete(`${onlinePbxEndpoints.PIPE_SETTING}/${pipeSettingsId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.ONLINE_PBX_PIPE_SETTINGS, id]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useEditPipeOnlinePbxPipeStatus(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: OnlinePbxPipeSettingsBody) => {
      const res = await $api.post(onlinePbxEndpoints.PIPE_SETTING, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
        qc.invalidateQueries([queryNames.ONLINE_PBX_PIPE_SETTINGS, id]);
      },
      onError: (err: any) => {
        err?.response && baseErrorHandler(err);
      }
    }
  );
}

export function useCallOnlinePbx(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { domain: string; from: string; to: string; key: string; keyId: string; fromOrigNumber?: string; fromOrigName?: string }) => {
      let to = String(helper.deleteNotNumbers(data.to, null));

      if (to && to.length > 10) {
        to = to.slice(to.length - 9, to.length);
      }
      const res = await axios.post(
        `${ONLINE_PBX_API}/${data.domain}/call/now.json`,
        {
          to,
          from: data.from,
          from_orig_number: data.fromOrigNumber,
          from_orig_name: data.fromOrigName
        },
        {
          headers: {
            'x-pbx-authentication': `${data.keyId}:${data.key}`
          }
        }
      );

      return res.data;
    },
    {
      onSuccess: data => {
        if (data.status === '0') {
          message.error(data.comment);
        } else {
          onSuccess && onSuccess();
        }
        qc.invalidateQueries([queryNames.ONLINE_PBX_HISTORY_CALL]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMarketplaceCreate() {
  const qc = useQueryClient();

  return useMutation<IData<Marketplace>, unknown, { redirectUrl: string; houses: number[]; extra: string }>(
    async data => {
      const res = await $api.post(marketplaceEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.MARKETPLACE]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFlatField() {
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      id?: number;
      name: string;
      type: string;
      show: boolean;
      showPDF: boolean;
      options?: {
        name: string;
        id?: number;
      }[];
    }) => {
      const res = await $api.post(flatEndpoints.FIELD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FLAT_FIELD]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFlatFieldDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${flatEndpoints.FIELD}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.FLAT_FIELD]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSavePaymentConfig() {
  const qc = useQueryClient();

  return useMutation(
    async (data: PaymentConfig) => {
      const res = await $api.post(paymentEndpoints.TYPE_CONFIG, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.PAYMENT_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSavePlanFact(startDate: string, finishDate: string, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: PlanFactSave) => {
      const res = await $api.post(statisticsEndpoints.PLAN_FACT_PIPE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
        qc.invalidateQueries([queryNames.PLAN_FACT_PIPE, startDate, finishDate]);
        qc.invalidateQueries([queryNames.CRM_STATISTICS_COMPANY_FIELD, startDate, finishDate]);
        qc.invalidateQueries([queryNames.CRM_STATISTICS_ONE_LEAD_COST, startDate, finishDate]);
        qc.invalidateQueries([queryNames.PLAN_FACT_COST_COMPANY_LEAD_FIELD]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSavePlanFactCost(startDate: string, finishDate: string, page: number, typeCost: boolean, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreatePlanFactCost) => {
      const res = await $api.post(statisticsEndpoints.PLAN_FACT_COST, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.PLAN_FACT_COST, page]);
        qc.invalidateQueries([queryNames.CRM_STATISTICS_ONE_LEAD_COST, startDate, finishDate, typeCost]);
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeletePlanFactCost(page: number, startDate: string, finishDate: string, costTypeLead: boolean) {
  const qc = useQueryClient();

  return useMutation(
    async (data: number) => {
      const res = await $api.delete(`${statisticsEndpoints.PLAN_FACT_COST}/${data}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.CRM_STATISTICS_ONE_LEAD_COST, startDate, finishDate, costTypeLead]);
        qc.invalidateQueries([queryNames.PLAN_FACT_COST, page]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSendMessageWithBot(onSuccess?: () => void) {
  return useMutation(
    async (data: SendMessageBodyBot) => {
      const res = await $api.post(telegramEndpoints.BOT_SEND_MESSAGE_USERS, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateContractField(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CrmField) => {
      const res = await $api.post(contractEndpoints.FIELD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.CONTRACT_FIELD]);
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteContractField(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${contractEndpoints.FIELD}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.CONTRACT_FIELD]);
        showMessage(data.message, data.accept);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useUyqurMutate() {
  return useMutation(
    async (data: { redirectUrl: string; extra: string; builders: UyqurBuilders[] }) => {
      const res = await $api.post(uyqurEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSendCodeChangePassword(onSuccess?: (data: { phone: string; username: string }) => void) {
  return useMutation(
    async (data: string) => {
      const res = await $api.get(`${resetPasswordEndpoints.SEND_CODE}?username=${data}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess && onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useVerifyCodeChangePassword(onSuccess?: () => void) {
  return useMutation(
    async (data: { username: string; verifyCode: string }) => {
      const res = await $api.get(`${resetPasswordEndpoints.VERIFY_CODE}?username=${data.username}&verifyCode=${data.verifyCode}`);

      return res.data;
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      }
    }
  );
}

export function useChangePassword(onSuccess?: () => void) {
  return useMutation(
    async (data: { username: string; password: string }) => {
      const res = await $api.get(`${resetPasswordEndpoints.CHANGE_PASSWORD}?username=${data.username}&password=${data.password}`);

      return res.data;
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useSaveCurrency(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: CurrencyItem) => {
      const res = await $api.post(currencyEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CURRENCY_LIST]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteCurrency(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: number) => {
      const res = await $api.delete(currencyEndpoints.INDEX + data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.CURRENCY_LIST]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useFindClientOnlinePbxDrawer() {
  const { push } = useQueryParam();

  return useMutation(
    async (search: number) => {
      const res = await $api.get(`${crmLeadEndpoints.INDEX}?search=${urlEncode(`${search}`)}`);

      return res.data.data as OneLid[];
    },
    {
      onSuccess: data => {
        if (data.length > 0) {
          push(`${rootPaths.MAIN + MainPaths.CRM}/${data[0].id}/${encodeString(data[0].name)}`);
        } else {
          message.error('Lead topilmadi!');
        }
      },
      onError: baseErrorHandler
    }
  );
}

export function useEditBotChats(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: BotChat) => {
      const res = await $api.post(telegramEndpoints.BOT_CHAT, data);

      return res.data.data;
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        qc.invalidateQueries([queryNames.TELEGRAM_BOT_CHATS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteBotChat(chatId: number, telegramId: number) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${telegramEndpoints.BOT}/${telegramId}/chats/${chatId}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.TELEGRAM_BOT_CHATS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useStartCountTimeNotTaskLeadNotification() {
  return useMutation(async () => { });
}

export function useWidgetSend() {
  const qc = useQueryClient();

  return useMutation(
    async (data: Widget) => {
      const res = await $api.post(widgetEndpoints.INDEX, data);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.WIDGET]);
      }
    }
  );
}

export function useContractDownloadPdf() {
  const qc = useQueryClient();

  return useMutation(
    async (data: DownloadPdf[] | undefined) => {
      const res = await $api.post(downloadpdfEndpoints.INDEX, data, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `home-info`, 'pdf');
        qc.invalidateQueries([queryNames.DOWNLOAD_PDF]);
      }
    }
  );
}

export function useContractDownloadPdfV2() {
  const qc = useQueryClient();

  return useMutation(
    async (data: DownloadPdf[] | undefined) => {
      const res = await $api.post(downloadpdfEndpoints.V2, data, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `home-info`, 'pdf');
        qc.invalidateQueries([queryNames.DOWNLOAD_PDF]);
      }
    }
  );
}

export function useDocumentTemplate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id?: number; language: string; fileID: number; logic?: string; type: string; customTempId?: number }) => {
      const res = await $api.post(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${data.type}`, {
        id: data.id,
        language: data.language,
        fileID: data?.fileID,
        logic: data?.logic,
        customTempId: data?.customTempId
      });

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.DOCUMENT_TEMPLATE]);
      }
    }
  );
}

export function useActiveSessionDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id: number; allSession: boolean }) => {
      const res = await $api.delete(`${activeSessionEndpoints.INDEX}?id=${data?.id}&allSession=${data?.allSession}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.ACTIVE_SESSION]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useReportConfig() {
  const qc = useQueryClient();

  return useMutation(
    async (data: Reports) => {
      const res = await $api.post(reportConfigEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.REPORT_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMonetaryConfigSet() {
  const qc = useQueryClient();

  return useMutation(
    async (data: MonetaryConfig | undefined) => {
      const res = await $api.post(monetaryConfig.CONFIG, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.MONETARY_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMonetaryPayment() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { paymentId: number; contractId?: number; note?: string; currencyId?: number; exchange?: number; currencyAmount?: number }) => {
      const res = await $api.patch(monetaryEndpoints.PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_FILTER]);
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_ID]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMonetaryAccept() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.patch(monetaryEndpoints.PAYMENT_ACCEPT, {
        paymentId: id
      });

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_FILTER]);
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_FILTER_COUNT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMonetaryRollback() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.patch(`${monetaryEndpoints.PAYMENT_ROLLBACK}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_FILTER]);
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_ID]);
        qc.invalidateQueries([queryNames.MONETARY_PAYMENT_FILTER_COUNT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateBuilding() {
  const qc = useQueryClient();
  const { params } = useQueryParam();

  return useMutation(
    async (data: {
      id?: number | undefined;
      sale?: boolean;
      name?: string;
      floor?: number;
      startDate?: string;
      finishDate?: string;
      houseId: number;
      priceCalculateType?: PriceCalculateType;
    }) => {
      const res = await $api.post(buildingEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.BUILDING_ID, params.id]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useExtraDay() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.post(`${companyEndpoints.EXTRA_DAY}?day=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.EXPIRE_DATE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useRealtorConfigCreate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { username: string; password: string; companyName: string; id?: number }) => {
      const res = await $api.post(realtorCongigEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.REALTOR_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function usePostMyHomeConfig() {
  const qc = useQueryClient();

  return useMutation(
    async (data: MyHomeConfigModel) => {
      const res = await $api.post(myHomeConfigEndPoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.MY_HOME_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useRealtorConfigDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(realtorCongigEndpoints.INDEX + id);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.REALTOR_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCurrencyCompanyId() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (id: number) => {
      const res = await $api.get(currencyEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      onSuccess: (data: CurrencyItem[]) => {
        dispatch(setCurrencyCompany(data));
      }
    }
  );
}

export function useUserGetOnlinePbx() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: { domain?: string; key?: string; keyId?: string; num?: string }) => {
      const res = await axios.post(
        `${ONLINE_PBX_API}/${data.domain}/user/get.json`,
        {
          num: data?.num,
          fields: 'webrtc'
        },
        {
          headers: {
            'x-pbx-authentication': `${data.keyId}:${data.key}`
          }
        }
      );

      return res.data.data;
    },
    {
      onSuccess: data => {
        if (data && data?.length > 0 && data[0] && data[0].webrtc) {
          dispatch(setWebRTC(data[0].webrtc));
        }
      }
    }
  );
}

export function useShouroomVisualAuth() {
  return useMutation(async () => {
    const res = await $api.get(amoCrmHouseEndpoints.X_AUTH_TOKEN);

    return res.data.data;
  });
}

export function useOnlinePBXRefresh() {
  return useMutation(async () => {
    const res = await $api.get(onlinePbxEndpoints.REFRESH_KEY);

    return res.data.data;
  });
}

export function useEmployeeChangePasswordDate() {
  const qc = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await $api.get(`${companyConfigEndpoints.EMPLOYEE_CHANGE_PASSWORD_DATE}?day=${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.EMPLOYEE_CHANGE_PASSWORD_DATE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useAccountPasswordChange() {
  const dispatch = useAppDispatch();

  return useMutation(
    async (password: string) => {
      const res = await $api.patch(accountEndpoints.PASSWORD, {
        password
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        dispatch(setUser(data?.data));
        signInSuccess(data?.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useTableConfigCustomMutation(key: TableKeyCustom, onClose?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { key: TableKeyCustom; value: string; allUser?: boolean }) => {
      const res = await $api.post(tableEndpoints.INDEX, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.TABLE, key]);
        onClose && onClose();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCallHistoryGetRecord() {
  return useMutation(
    async (data: CallHistoryGetRecord) => {
      const res = await $api.post(callHistoryEndpoints.GET_RECORD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useFlatChangePrice() {
  return useMutation(
    async (data: { houseId: number; priceChangeType: PriceChangeType; value: number; increase: boolean }) => {
      const res = await $api.post(flatEndpoints.CHANGE_PRICE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadFlatsToExcelSummary(size: number, searchObj: SearchParamsHome) {
  return useMutation(
    async () => {
      const res = await $api.post(`${flatEndpoints.FILTER_SUMMARY_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getFlatsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadFlatsToExcelSummaryBeta(size: number, searchObj: SearchParamsHome) {
  return useMutation(
    async () => {
      const res = await $api.post(`${flatEndpoints.FILTER_SUMMARY_EXCEL_BETA}?lang=${i18n.language?.toUpperCase()}`, getFlatsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useFlatUndoPrice() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { houseId?: number; buildingId?: number }) => {
      const res = await $api.get(`${flatEndpoints.UNDO_PRICE}?houseId=${data?.houseId}&buildingId=${data?.buildingId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.FLAT_ALL]);
        qc.invalidateQueries([queryNames.FLAT]);
        qc.invalidateQueries([queryNames.FLAT_PRICE_CHANGES_DATE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

// create company template shablon mutations
export function useCreateCustomDocumentTemplate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { name: string; id?: number }) => {
      const res = await $api.post(companyConfigEndpoints.DOCUMENT_TEMPLATE_CUSTOM, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryNames.DOCUMENT_TEMPLATE_CUSTOM]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteCustomDocTemplate() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (customTempId: number) => {
      const res = await $api.delete(`${companyConfigEndpoints.DOCUMENT_TEMPLATE_CUSTOM}?customTempId=${customTempId}`);

      return res.data;
    },

    onSuccess: data => {
      qc.invalidateQueries([queryNames.DOCUMENT_TEMPLATE_CUSTOM]);
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}

export function useDownloadPaymentBookingToWord() {
  return useMutation(
    async (data: { id: number; number: string; bookingId?: number }) => {
      // const res = await $api.get(`${contractEndpoints.INDEX + data.contractId}/payment/${data.id}/template`, {
      const res = await $api.get(`${bookingEndpoints.INDEX + data.bookingId}/payment/${data.id}/template`, {
        responseType: 'blob'
      });

      return {
        data: res.data,
        number: data.number
      };
    },
    {
      onSuccess: data => {
        downloadFile(data.data, `payment-${data.number}`, '.docx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useCreateEmbedWidget() {
  return useMutation({
    mutationFn: async (data: { name: string; code: string; active: boolean; id?: number }) => {
      const res = await $api.post('/v1/embed-widget', data);

      return res.data;
    },
    onSuccess: data => showMessage(data.message, data.accept),
    onError: baseErrorHandler
  });
}

export function useUpdateNumerationConfig() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id: number; value: string }) => {
      const res = await $api.post('/v1/company-config/contract-number-pattern-value', data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.NUMERATION_CONFIG]);
      },
      onError: baseErrorHandler
    }
  );
}
