import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelectionContainer } from '@air/react-drag-to-select';
import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import ChangesUndoIcon from '@icons/ChangesUndoIcon';
import RowVerticalIcon from '@icons/RowVerticalIcon';
import SaleFlatIcon from '@icons/SaleFlatIcon';
import { Button, Dropdown, Layout, Spin } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import DragIcon from '../../assets/icons/DragIcon';
import GlphIcon from '../../assets/icons/GlphIcon';
import SlashCircleHouseCardIcon from '../../assets/icons/houseCard/SlashCircleHouseCardIcon';
import InformationIcon from '../../assets/icons/InformationIcon';
import MiniMenuIcon from '../../assets/icons/MiniMenuIcon';
import SaveBlueIcon from '../../assets/icons/SaveBlueIcon';
import SaveGreenIcon from '../../assets/icons/SaveGreenIcon';
import SaveRedIcon from '../../assets/icons/SaveRedIcon';
import BasementShaxmatkaIcon from '../../assets/icons/shaxmatka/BasementShaxmatkaIcon';
import DiceShaxmatkaIcon from '../../assets/icons/shaxmatka/DiceShaxmatkaIcon';
import DoorShaxmatkaIcon from '../../assets/icons/shaxmatka/DoorShaxmatkaIcon';
import EditShaxmatkaIcon from '../../assets/icons/shaxmatka/EditShaxmatkaIcon';
import TrashShaxmatkaIcon from '../../assets/icons/shaxmatka/TrashShaxmatkaIcon';
import StatusCardIcon from '../../assets/icons/StatusCardIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCreateBuilding, useFlatCreate, useFlatDelete } from '../../queries/mutation';
import { useBuildingId, useFlat } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';
import { setPriceCalculateType, setShaxmatka, setTableMode } from '../../store/reducers/ShaxmatkaSlice';
import { FlatId } from '../../utils/models/Flat';

import CreateBuildingModal from './components/CreateBuildingModal';
import CreateEntranceModal from './components/CreateEntranceModal';
import CreateFloorModal from './components/CreateFloorModal';
import CreatePadvalModal from './components/CreatePadvalModal';
import DeleteBuildingModal from './components/DeleteBuildingModal/DeleteBuildingModal';
import EntranceDoorModal from './components/EntranceDoorModal';
import EntranceSortNumberModal from './components/EntranceSortNumberModal';
import IncrementPrice from './components/increment-price/IncrementPrice';
import OpenDrawer from './components/OpenDrawer';
import ShaxmatkaHeader from './components/ShaxmatkaHeader';
import ShaxmatkaSpinner from './components/ShaxmatkaSpinner';
import UndoModal from './components/undo-modal/UndoModal';
import ShaxmatkaTable from './features/shaxmatka-table/ShaxmatkaTable';
import { allSave } from './helper/allSave';
import { getSelectedCheckbox } from './helper/getSelectedCheckbox';
import { handleSelection } from './helper/handleSelection';
import { ShaxmatkaContext, ShaxmatkaContextDataState } from './hooks/ShaxmatkaContext';
import ShaxmatkaEntrance from './shaxmatka-entrance/ShaxmatkaEntrance';
import ShaxmatkaEntranceHeader from './shaxmatka-entrance-header/ShaxmatkaEntranceHeader';

import styles from './shaxmatka.module.scss';

const { Content } = Layout;

const MouseSelection: FC<any> = React.memo(({ onSelectionChange, onSelectionEnd }) => {
  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById('Shaxmatka_body_container'),
    onSelectionChange,
    onSelectionEnd,
    selectionProps: {
      className: 'selected',
      style: {
        margin: '40px 0 0 80px',
        zIndex: 1
      }
    }
  });

  return <DragSelection />;
});

const Shaxmatka = () => {
  // shaxmatka context state start
  const { t } = useTranslation();
  const [shaxmatkaContext, setShaxmatkaContext] = useState<ShaxmatkaContextDataState>({
    incrementPrice: ''
  });

  const setContext = (value: ShaxmatkaContextDataState) => {
    setShaxmatkaContext(prev => ({ ...prev, ...value }));
  };
  // shaxmatka context state finish

  const [padvalVisible, setPadvalVisible] = useState(false);
  const [createFloorModal, setCreateFloorModal] = useState({
    visible: false,
    data: {}
  });
  const [saveAll, setSaveAll] = useState(true);
  const [selectVsible, setSelectVsible] = useState(false);
  const selectableItems = useRef<any>([]);
  const [saveLoad, setSaveLoad] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const dispatch = useAppDispatch();
  const { data: shaxmatka, priceCalculateType, dataDelete: shaxmatkaDelete, tableMode } = useAppSelector(state => state.shaxmatkaReducer);
  const [windowSize, setWindowSize] = useState(0);
  const { data: buildingId, isLoading: buildingIdLoading } = useBuildingId();
  const [createBuildingVisible, setCreateBuildingVisible] = useState<any>({
    visible: false,
    id: null
  });
  const [entranceDoorVisible, setEntranceDoorVisible] = useState(false);
  const [repaired, setRepaired] = useState(false);
  const [flatCopy, setFlatCopy] = useState<FlatId>();
  const [maxCol, setMaxCol] = useState<any[]>();
  const [room, setRoom] = useState<FlatId | any>();
  const [createEntrance, setCreateEntrance] = useState({
    visible: false,
    title: "qo'shish"
  });
  const [deleteBuildingVisible, setDeleteBuildingVisible] = useState(false);
  const [sortNumberModal, setSortNumberModal] = useState(false);
  const [openUndo, setOpenUndo] = useState(false);
  const { params, push, location, searchParams } = useQueryParam<any, any>();
  const position = useRef<any>();
  const activeKey = Number(queryString.parseUrl(location.search).query?.blockId);

  const { data: flat, isLoading: flatLoading, isFetching: flatFetching } = useFlat(activeKey || 0);
  const createBuilding = useCreateBuilding();

  const isSales = buildingId?.find(item => item?.id === activeKey)?.sale || false;

  const saveFlat = useFlatCreate(activeKey);

  const flatDelete = useFlatDelete();

  useEffect(() => {
    if (!activeKey) {
      if (buildingId && buildingId[0]) {
        push({
          search: `?blockId=${buildingId[0]?.id}&blockName=${buildingId[0]?.name}`
        });
      }
    }
    // eslint-disable-next-line
  }, [buildingId]);

  useEffect(() => {
    const mSpinning: any = document.querySelector('.mSpinning');

    if (mSpinning) {
      mSpinning.style.display = 'flex';
      if (!flatLoading || !flatFetching) {
        mSpinning.style.display = 'none';
      }
    }
  }, [flatLoading, flatFetching, shaxmatka, activeKey]);

  useEffect(() => {
    let ArrPodezLength = 0;
    const ArrFloorLength: any = [];
    let ArrXaxis = 0;
    let ArrYaxis = 0;
    let lenLength = 0;

    setMaxCol([]);
    shaxmatka.forEach((floor: any) => {
      floor?.map((el: any) =>
        el.forEach((el: any) => {
          lenLength = lenLength > el.floor ? lenLength : el.floor;
          ArrPodezLength = ArrPodezLength > el.entrance ? ArrPodezLength : el.entrance;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          !ArrFloorLength.includes(el.floor) && ArrFloorLength.push(el.floor);
          if (el?.xaxis && el?.xaxis[0]) {
            ArrXaxis = ArrXaxis > el?.xaxis[0] ? ArrXaxis : el?.xaxis[0];
          }
          if (el?.yaxis && el?.yaxis[0]) {
            ArrYaxis = ArrYaxis > el?.yaxis[0] ? ArrYaxis : el?.yaxis[0];
          }
        })
      );
    });

    const len = lenLength - ArrYaxis + 1;

    for (let i = len; i <= lenLength; i++) {
      if (!ArrFloorLength.includes(i)) {
        ArrFloorLength.push(i);
      }
    }

    ArrFloorLength.sort((a: any, b: any) => b - a);

    setMaxCol(ArrFloorLength);
  }, [flat, dispatch, shaxmatka]);

  useEffect(() => {
    const activeBuilding = buildingId?.find(item => item.id === Number(activeKey));

    dispatch(setPriceCalculateType(activeBuilding?.priceCalculateType || undefined));
  }, [flat, activeKey]);

  useEffect(() => {
    setWindowSize(Number(document.getElementById('Shaxmatka_body_container')?.clientWidth));
    window.addEventListener('resize', () => {
      setWindowSize(Number(document.getElementById('Shaxmatka_body_container')?.clientWidth));
    });
  }, [shaxmatka]);

  const allSelectChange = (e: any) => {
    if (e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')) {
      e.target.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
    } else {
      e.target.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
    }

    document.querySelectorAll('.selectableItems').forEach((item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked') ? (item.style.border = '1.5px solid #00A389') : (item.style.border = '');
      item.setAttribute(
        'data',
        JSON.stringify({
          ...JSON.parse(item.getAttribute('data')),
          isSelected: e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')
        })
      );
    });

    setSelectVsible(!selectVsible);
  };

  const leftSelectChange = (value: any, e: any, number: number) => {
    if (e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')) {
      e.target.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
    } else {
      e.target.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
    }

    document.querySelectorAll('.selectableItems').forEach((item: any) => {
      const room = JSON.parse(item.getAttribute('data'));

      if (String(room.yaxis[0]) === String(number)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked') ? (item.style.border = '1.5px solid #00A389') : (item.style.border = '');
        item.setAttribute(
          'data',
          JSON.stringify({
            ...JSON.parse(item.getAttribute('data')),
            isSelected: e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')
          })
        );
      }
    });

    setSelectVsible(!selectVsible);
  };

  const bottomSelectChange = (value: any, e: any, podezh: any) => {
    if (e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')) {
      e.target.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
    } else {
      e.target.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
    }
    document.querySelectorAll('.selectableItems').forEach((item: any) => {
      const room = JSON.parse(item.getAttribute('data'));

      if (room.xaxis[0] === value && room.entrance === podezh) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked') ? (item.style.border = '1.5px solid #00A389') : (item.style.border = '');
        item.setAttribute(
          'data',
          JSON.stringify({
            ...JSON.parse(item.getAttribute('data')),
            isSelected: e.target.querySelector('.ant-checkbox').classList.contains('ant-checkbox-checked')
          })
        );
      }
    });

    setSelectVsible(!selectVsible);
  };

  const allSaveProps = {
    setSaveLoad,
    activeKey,
    saveFlat,
    shaxmatkaDelete,
    flatDelete,
    setSaveAll,
    priceCalculateType,
    shaxmatka,
    tableMode
  };

  // onSave
  const onSave = () => {
    allSave(allSaveProps);
  };

  // mSpinning
  useEffect(() => {
    const mSpinning: any = document.querySelector('.mSpinning');

    if (mSpinning) {
      if (saveFlat.isSuccess || saveFlat.isError) {
        setSaveLoad(false);
        mSpinning.style.display = 'none';
      }
    }
  }, [saveFlat]);

  // createApartamentProps
  const createApartamentProps = {
    dispatch,
    setSaveAll,
    shaxmatka,
    activeKey
  };

  // buildingSortNumber
  const buildingSortNumber = (value: string) => {
    setSaveAll(false);
    if (value === 'bino') {
      setSortNumberModal(true);
    } else {
      let shaxmatkaCopy = [...shaxmatka];

      shaxmatkaCopy = shaxmatkaCopy.map((podezd: any[]) => [...podezd]?.reverse());

      shaxmatkaCopy = shaxmatkaCopy.map((podez: any[]) => {
        let number = 0;

        return podez.map((floor: any[], floorIndex: number) =>
          floor.map((room: any, roomIndex: number) => {
            if (!room.new) {
              // eslint-disable-next-line no-plusplus
              number++;
              return {
                ...room,
                number: String(number)
              };
            }
            return room;
          })
        );
      });
      dispatch(setShaxmatka(shaxmatkaCopy as any));
    }
  };

  // deleteHandleBuilding
  const deleteHandleBuilding = () => {
    setDeleteBuildingVisible(true);
  };

  // handleSelectionChange
  const handleSelectionChange = (e: any) => {
    position.current = e;
  };

  // handleSelectionEnd
  const handleSelectionEnd = (e: any) => {
    handleSelection(position.current, {
      setSelectVsible,
      selectableItems
    });
    setSelectVsible(true);
  };

  // getSelectedCheckbox
  useEffect(() => {
    getSelectedCheckbox();
  }, [selectVsible, room]);

  // saleBuilding
  const saleBuilding = () => {
    const block = buildingId?.find(item => item?.id === Number(searchParams?.blockId));

    createBuilding.mutate({
      ...block,
      id: Number(searchParams?.blockId),
      name: String(searchParams?.blockName),
      sale: !isSales,
      houseId: Number(params.id)
    });
  };

  // handle open undo modal
  const openUndoModal = () => {
    setOpenUndo(true);
  };

  const handleTableMode = () => {
    dispatch(setTableMode(!tableMode));
  };

  return (
    <ShaxmatkaContext.Provider
      value={{
        ...shaxmatkaContext,
        setContext
      }}
    >
      <MainHeader>
        <BreadcrumbHeader backUrl={rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.HOUSE_CONFIG} />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <>
          <div className={styles.Shaxmatka}>
            <Spin spinning={buildingIdLoading}>
              <ShaxmatkaHeader buildings={buildingId} setCreateBuildingVisible={setCreateBuildingVisible} setContext={setContext} />
              {buildingId?.length === 0 && (
                <div className={styles.Shaxmatka_create_building}>
                  <span>
                    <InformationIcon />
                  </span>
                  <p>{t('shaxmatka.Bino_yoki_bloklar_kiritishni_amalga_oshirish_zarur')}</p>
                  <Button
                    type="primary"
                    onClick={() =>
                      setCreateBuildingVisible({
                        visible: true,
                        id: null
                      })
                    }
                  >
                    + {t('shaxmatka.Bino_yaratish')}
                  </Button>
                </div>
              )}
              {buildingId?.length !== 0 && (
                <div className={styles.Shaxmatka_container}>
                  <div className={styles.Shaxmatka_body}>
                    <div className={styles.Shaxmatka_body_header}>
                      <div>
                        <h1>{buildingId?.map(i => i.id === Number(activeKey) && i.name)}</h1>
                        <div>
                          <Button type={repaired ? 'primary' : 'default'} onClick={() => setRepaired(true)}>
                            {t('shaxmatka.Tamirli')}
                          </Button>
                          <Button type={!repaired ? 'primary' : 'default'} onClick={() => setRepaired(false)}>
                            {t('shaxmatka.Tamirsiz')}
                          </Button>
                        </div>
                        <Dropdown
                          overlay={
                            <div className={styles.Shaxmatka_info}>
                              <div>
                                <SaveGreenIcon /> {t('shaxmatka.Turar_joy')}
                              </div>
                              <div>
                                <SaveBlueIcon /> {t('shaxmatka.Noturar_joy')}
                              </div>
                              <div>
                                <StatusCardIcon borderColor="#F4B249" fillColor="#FFDCA5" /> {t('shaxmatka.Bron_qilingan')}
                              </div>
                              <div>
                                <StatusCardIcon borderColor="#56C776" fillColor="#A7EBBA" /> {t('shaxmatka.Sotilgan')}
                              </div>
                              <div>
                                <StatusCardIcon borderColor="#BDBDBD" fillColor="#EFEFEF" /> {t('shaxmatka.Sotilmaydi')}
                              </div>
                              <div>
                                <StatusCardIcon borderColor="#F04041" fillColor="#FBC6C6" /> {t('shaxmatka.Xatolik_mavjud')}
                              </div>
                              <div>
                                <SaveRedIcon /> {t('shaxmatka.Chegirma')}
                              </div>
                            </div>
                          }
                          placement="bottomRight"
                        >
                          <Button
                            type="text"
                            shape="circle"
                            style={{
                              border: 'none',
                              marginLeft: 10,
                              background: 'none'
                            }}
                          >
                            <GlphIcon />
                          </Button>
                        </Dropdown>
                        <RowVerticalIcon className={`${styles.tableMode} ${tableMode ? styles.tableMode_active : ''}`} onClick={handleTableMode} />
                      </div>
                      <div>
                        {!saveAll && (
                          <p
                            style={{
                              marginRight: 10,
                              color: '#ff0000',
                              fontSize: 12
                            }}
                          >
                            <span
                              style={{
                                color: '#ffffff',
                                background: '#ff0000',
                                width: 20,
                                height: 20,
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%'
                              }}
                            >
                              !
                            </span>{' '}
                            {t('shaxmatka.Saqlanmagan')}
                          </p>
                        )}
                        <Button type="primary" style={{ borderRadius: 6 }} onClick={onSave} loading={saveLoad}>
                          {t('shaxmatka.Saqlash')}
                        </Button>
                        <Dropdown
                          overlay={
                            <div className={styles.Shaxmatka_podez_dropdown}>
                              <div
                                onClick={() =>
                                  setCreateBuildingVisible({
                                    visible: true,
                                    id: Number(activeKey)
                                  })
                                }
                              >
                                <EditShaxmatkaIcon /> {t('shaxmatka.Tahrirlash')}
                              </div>
                              {shaxmatka?.length !== 0 && (
                                <>
                                  <div onClick={() => setContext({ incrementPrice: 'BLOCK' })}>
                                    <SaleFlatIcon /> {t('home.Narxni_ozgartirish')}
                                  </div>
                                  <div>
                                    <DiceShaxmatkaIcon /> {t('shaxmatka.Raqamlash')}
                                    <div className={styles.Shaxmatka_podez_dropdown_hover}>
                                      <div
                                        onClick={() => {
                                          buildingSortNumber('bino');
                                        }}
                                      >
                                        {t('shaxmatka.Bino_boyicha')}
                                      </div>
                                      <div
                                        onClick={() => {
                                          buildingSortNumber('eshik');
                                        }}
                                      >
                                        {t('shaxmatka.Kirish_eshigi_boyicha')}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <DoorShaxmatkaIcon /> {t('shaxmatka.Kirish_eshigi')}
                                    <div className={styles.Shaxmatka_podez_dropdown_hover}>
                                      <div
                                        onClick={() =>
                                          setCreateEntrance({
                                            visible: true,
                                            title: "qo'shish"
                                          })
                                        }
                                      >
                                        {t('shaxmatka.Qoshish')}
                                      </div>
                                      <div
                                        onClick={() =>
                                          setCreateEntrance({
                                            visible: true,
                                            title: "o'chirish"
                                          })
                                        }
                                      >
                                        {t('shaxmatka.Ochirish')}
                                      </div>
                                    </div>
                                  </div>
                                  <div onClick={() => setPadvalVisible(true)}>
                                    <BasementShaxmatkaIcon />
                                    {t('shaxmatka.padval_qushish')}
                                  </div>
                                </>
                              )}
                              <div onClick={saleBuilding}>
                                <SlashCircleHouseCardIcon />
                                <span>{isSales ? t('house_config.Sotuvdan_chiqarish') : t('house_config.Sotuvga_chiqarish')}</span>
                              </div>
                              <div onClick={openUndoModal}>
                                <ChangesUndoIcon />
                                <span>{t('shaxmatka.Ozgarishlar')}</span>
                              </div>
                              <div onClick={deleteHandleBuilding}>
                                <TrashShaxmatkaIcon /> {t('shaxmatka.Ochirish')}
                              </div>
                            </div>
                          }
                          placement="bottomRight"
                          trigger={['click']}
                        >
                          <Button shape="circle" style={{ border: 'none', marginLeft: 10 }}>
                            <MiniMenuIcon />
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                    <ShaxmatkaSpinner />
                    {tableMode && shaxmatka?.length !== 0 ? (
                      <ShaxmatkaTable
                        loading={flatLoading}
                        repaired={repaired}
                        activeKey={activeKey}
                        flatCopy={flatCopy}
                        setCreateFloorModal={setCreateFloorModal}
                        setDeleteLoad={setDeleteLoad}
                        setFlatCopy={setFlatCopy}
                        setRoom={setRoom}
                        setSaveAll={setSaveAll}
                      />
                    ) : (
                      <div>
                        <div className={styles.Shaxmatka_body_container} id="Shaxmatka_body_container">
                          <ShaxmatkaEntranceHeader shaxmatka={shaxmatka} setSaveAll={setSaveAll} />
                          <div className={styles.Shaxmatka_body_container_left}>
                            <div className={styles.Shaxmatka_body_container_left_number}>
                              {shaxmatka.length > 0 && <div>{t('crm.Hammasi')}</div>}
                              {maxCol?.map(i => <div key={i}>{i}</div>)}
                            </div>
                            <div className={styles.Shaxmatka_body_container_left_checkbox}>
                              {shaxmatka.length > 0 && (
                                <div>
                                  <label onClick={e => allSelectChange(e)} className="allCheckbox ant-checkbox-wrapper">
                                    <span className="ant-checkbox" style={{ pointerEvents: 'none' }}>
                                      <span className="ant-checkbox-inner" style={{ pointerEvents: 'none' }} />
                                    </span>
                                  </label>
                                </div>
                              )}
                              {maxCol?.map((i, number: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={number}>
                                  <label className="leftCheckbox ant-checkbox-wrapper" id={String(number + 1)} onClick={(e: any) => leftSelectChange(i, e, number + 1)}>
                                    <span className="ant-checkbox" style={{ pointerEvents: 'none' }}>
                                      <span className="ant-checkbox-inner" style={{ pointerEvents: 'none' }} />
                                    </span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className={styles.Shaxmatka_body_container_center}>
                            <MouseSelection onSelectionChange={handleSelectionChange} onSelectionEnd={handleSelectionEnd} />
                            {shaxmatka?.length !== 0 ? (
                              shaxmatka?.map((floor: any, index: number) => {
                                let max = 0;

                                floor.forEach((i: any) => max < i.length && (max = i.length));
                                return (
                                  <ShaxmatkaEntrance
                                    max={max}
                                    activeKey={activeKey}
                                    bottomSelectChange={bottomSelectChange}
                                    createApartamentProps={createApartamentProps}
                                    flatCopy={flatCopy}
                                    floor={floor}
                                    repaired={repaired}
                                    setCreateFloorModal={setCreateFloorModal}
                                    setDeleteLoad={setDeleteLoad}
                                    setFlatCopy={setFlatCopy}
                                    setRoom={setRoom}
                                    setSaveAll={setSaveAll}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    index={index}
                                  />
                                );
                              })
                            ) : (
                              <>
                                <div
                                  className={styles.Shaxmatka_create_building}
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <span>
                                    <InformationIcon />
                                  </span>
                                  <p>{t('shaxmatka.Bino_yoki_bloklar_kiritishni_amalga_oshirish_zarur')}</p>
                                  <Button type="primary" onClick={() => setEntranceDoorVisible(true)}>
                                    + {t('shaxmatka.Kirish_eshigi_yaratish')}
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                          <div className={styles.Shaxmatka_body_container_right}>
                            {maxCol?.map((i: any) => (
                              <div key={i}>
                                <DragIcon />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <OpenDrawer room={room} setRoom={setRoom} selectVsible={selectVsible} indexesToSelect={selectableItems.current} setSaveAll={setSaveAll} />
                </div>
              )}
            </Spin>
            <CreateBuildingModal visible={createBuildingVisible.visible} setVisible={setCreateBuildingVisible} id={createBuildingVisible.id} />
            <EntranceDoorModal visible={entranceDoorVisible} setVisible={setEntranceDoorVisible} />
            <CreateEntranceModal data={createEntrance} setVisible={setCreateEntrance} setSaveAll={setSaveAll} />
            <CreateFloorModal setVisible={setCreateFloorModal} visible={createFloorModal.visible} setSaveAll={setSaveAll} data={createFloorModal.data} />
            <CreatePadvalModal setSaveAll={setSaveAll} visible={padvalVisible} setVisible={setPadvalVisible} />
            <EntranceSortNumberModal setVisible={setSortNumberModal} visible={sortNumberModal} />
            <DeleteBuildingModal
              buildingId={buildingId}
              id={buildingId?.find(item => item.id === Number(activeKey)) ? buildingId?.find(item => item.id === Number(activeKey))?.id! : 0}
              name={buildingId?.find(item => item.id === Number(activeKey)) ? buildingId?.find(item => item.id === Number(activeKey))?.name! : ''}
              setVisible={setDeleteBuildingVisible}
              visible={deleteBuildingVisible}
            />
            <IncrementPrice context={shaxmatkaContext} setContext={setShaxmatkaContext} />
            <UndoModal setOpenUndo={setOpenUndo} openUndo={openUndo} />
          </div>
        </>
      </Content>
    </ShaxmatkaContext.Provider>
  );
};

export default Shaxmatka;
