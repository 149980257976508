import ArrowDownCrmIcon from '@icons/ArrowDownCrmIcon';
import { cx } from '@utils/cx';
import { Select as BasSelect, SelectProps } from 'antd';

const Select = (props: SelectProps) => (
  <BasSelect
    {...props}
    className={cx('customSelect w-full', props.className)}
    suffixIcon={<ArrowDownCrmIcon strokeColor="#a2a2a2" />}
    onChange={(value, options) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.onChange && props.onChange(value || null, options);
    }}
  />
);

Select.Option = BasSelect.Option;
export default Select;
