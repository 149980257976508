import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArrowCircleDownIcon from '@icons/contract/ArrowCircleDownIcon';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import i18n from '@service/I18nService';
import { NaturalPersonCreate } from '@utils/models/Client';
import { Button, Checkbox, Col, Dropdown, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import { Address } from 'modules/address';

import CalendarIcon from '../../assets/icons/CalendarIcon';
import GalleryIcon from '../../assets/icons/GalleryIcon';
import LocationIcon from '../../assets/icons/LocationIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useSaveHouse, useUploadFile } from '../../queries/mutation';
import { useBranchCompact, useBuildersCompact, useCurrencyList, useGetDistrict, useOneHouse, useOpenCagaData, useRegions } from '../../queries/queries';
import { clearHouseEditModalOpen } from '../../store/reducers/HouseConfigSlice';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { validateDateString } from '../../utils/helper/validatorDateString';
import { FileObject } from '../../utils/models/File';
import { GalleryPosition, GallerySave, GalleryShow } from '../../utils/models/Gallery';

import HouseLocationModal from './house-location-modal/HouseLocationModal';
import HouseImage from './HouseImage';

import styles from './houseConfig.module.scss';

interface FormValues {
  name: string;
  buildingStarts: string;
  release: string;
  address: string;
  builderID: number;
  abbreviation: string;
  sale: boolean;
  currencyId: number;
  branchID: number | null;
  districtID: number | null;
  cityID: number;
  countryCode?: string | null;
  country?: string;
  constructionFloor?: number | null;
  additionalStages?: string[] | null;
  constructionStage?: string | null;
  instagram: string;
  telegram: string;
  facebook: string;
  website: string;
  callCenter: string;
  salesOfficeCountryCode?: string;
  salesOfficeCityId?: number;
  salesOfficeDistrictId?: number;
  salesOfficeAddress?: string;
}

interface Props {
  clientData: NaturalPersonCreate | null;
}

const HouseEditAndCreateModal: React.FC<Props> = ({ clientData }) => {
  const { t } = useTranslation();
  const [targetId, setTargetId] = useState('');
  const [houseLocationVisible, setHouseLocationVisible] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [form] = Form.useForm<FormValues>();
  const [regionId, setRegionId] = useState<number>(0);
  const [gallery, setGallery] = useState<GalleryShow[]>([]);
  const [stages, setStages] = useState<string[]>([]);

  const [constructionStage, setConstructionStage] = useState<string | null>('');
  const [constructionFloor, setConstructionFloor] = useState<number | null>();
  const [contentVisible, setContentVisible] = useState(false);
  const [infoMaxHeight, setInfoMaxHeight] = useState(0);

  const plainOptions = ['PLUMBING', 'ELECTRONIC_COMMUNICATIONS', 'FACADE', 'BEAUTIFICATION'];

  const { data: regions, isLoading: loadingRegions } = useRegions();
  const { data: builders, isLoading: loadingBuildings } = useBuildersCompact();
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const { data: locationData } = useOpenCagaData(`q=${location.lat},${location.lng}`);
  const { editModalOpen, id } = useAppSelector(state => state.houseConfigReducer);
  const { data: house, isLoading: loadingHouse } = useOneHouse(id || 0);

  const salesOfficeCityId = Form.useWatch('salesOfficeCityId', form);
  const salesOfficeCountryCode = Form.useWatch('salesOfficeCountryCode', form);
  const dispatch = useAppDispatch();

  const handleContentVisible = () => {
    setContentVisible(!contentVisible);
  };

  const locationName = () => {
    if (locationData?.results[0] && location.lat && location.lng) {
      return locationData?.results[0]?.formatted;
    }
    return t('home.Joylashuv_beliglanmagan');
  };

  const [countryCode, setCountryCode] = useState<string | null>('uz');
  const { searchParams } = useQueryParam<{ search: string }, any>();

  const { data: currency, isLoading } = useCurrencyList();

  function getFile(data: FileObject[]) {
    const newGallery = [...gallery];

    newGallery.push({
      queue: newGallery.length,
      files: data[0],
      position: targetId === 'fileUpload2' ? GalleryPosition.HOUSE_LOGO : GalleryPosition.HOUSE_EXTRA
    });
    setGallery(newGallery);
  }

  function getFile1(data: FileObject[]) {
    const newGallery = [...gallery];

    newGallery.push({
      queue: newGallery.length,
      files: data[0],
      position: GalleryPosition.HOUSE_LOGO
    });
    setGallery(newGallery);
  }

  function handleCancel() {
    dispatch(clearHouseEditModalOpen());
    if (!house?.gallery) {
      setGallery([]);
    }
    setRegionId(0);
    setConstructionStage(null);
    setConstructionFloor(null);
    setStages([]);
    form.setFieldsValue({
      name: '',
      buildingStarts: '',
      release: '',
      address: '',
      sale: false,
      builderID: undefined,
      abbreviation: '',
      currencyId: 0,
      districtID: undefined,
      cityID: undefined,
      country: '',
      countryCode: '',
      additionalStages: null,
      constructionFloor: null,
      constructionStage: null,
      branchID: null,
      instagram: '',
      telegram: '',
      facebook: '',
      website: '',
      callCenter: ''
    });
  }

  const saveHouse = useSaveHouse(searchParams.search, handleCancel);
  const uploadFile = useUploadFile(getFile);
  const uploadFile2 = useUploadFile(getFile1);
  const { data: branchData } = useBranchCompact();
  const user = useAppSelector(state => state.userReducer.user);

  function createArray() {
    const result = [];

    for (let i = 1; i <= house?.floor!; i++) {
      result.push(i);
    }

    return result;
  }

  useEffect(() => {
    if (editModalOpen && id === undefined && currency) {
      const active = currency.find(item => item.basic);

      if (active) {
        form.setFieldsValue({
          currencyId: active.id
        });
      }
    }
  }, [editModalOpen, currency]);

  useEffect(() => {
    if (id && house && !loadingHouse && editModalOpen) {
      setConstructionFloor(house?.constructionFloor);
      setConstructionStage(house?.constructionStage!);
      setRegionId(house.city?.id || 0);
      setCountryCode(house.countryCode || 'uz');
      setStages(house?.additionalStages || []);
      form.setFieldsValue({
        ...house,
        name: house.name,
        buildingStarts: house.buildingStarts,
        release: house.release,
        address: house.address,
        builderID: house.builderID,
        abbreviation: house.abbreviation,
        currencyId: house.currency?.id,
        districtID: house.district?.id,
        cityID: house.city?.id,
        country:
          // @ts-expect-error
          house?.countryCode && countrCode?.find(el => el.code === house?.countryCode) ? countrCode?.find(el => el.code === house?.countryCode)[`country_${i18n.language}`] : null, // Установка country
        countryCode: house.countryCode ? house.countryCode : countryCode, // Установка countryCode
        branchID: house?.branch ? house?.branch?.id : null,
        facebook: house?.facebook,
        instagram: house?.instagram,
        telegram: house?.telegram,
        website: house?.website,
        salesOfficeCityId: house?.salesOfficeCity?.id,
        salesOfficeDistrictId: house?.salesOfficeDistrict?.id
      });
      if (house.gallery) {
        setGallery(house.gallery);
      } else {
        setGallery([]);
      }
      if (house.latitude && house.longitude) {
        setLocation({ lat: house.latitude, lng: house.longitude });
      } else {
        setLocation({ lat: 0, lng: 0 });
      }
    }
  }, [id, house, loadingHouse, editModalOpen]);

  const onFinish = (values: FormValues) => {
    const gallerySave: GallerySave[] = [];
    const { branchID, ...rest } = values;

    gallery?.forEach(item => {
      gallerySave.push({
        id: item?.id,
        position: item?.position,
        queue: item?.queue,
        fileID: item?.files?.id
      });
    });

    const formData = {
      ...rest,
      constructionFloor: constructionStage === 'FLOOR' ? constructionFloor : null,
      // eslint-disable-next-line no-nested-ternary
      additionalStages: constructionStage === 'ROOF' ? (stages?.length! > 0 ? stages : null) : null,
      gallery: gallerySave.length > 0 ? gallerySave : null,
      name: values.name?.trim(),
      abbreviation: values.abbreviation?.trim(),
      latitude: location.lat,
      longitude: location.lng,
      country: values.country,
      countryCode: values.countryCode || null,
      constructionStage: constructionStage || null,
      salesOfficeCountryCode: values?.salesOfficeCountryCode || null
    };

    const formDataUpdate = {
      ...values,
      constructionFloor: constructionStage === 'FLOOR' ? constructionFloor : null,
      // eslint-disable-next-line no-nested-ternary
      additionalStages: constructionStage === 'ROOF' ? (stages?.length! > 0 ? stages : null) : null,
      gallery: gallerySave.length > 0 ? gallerySave : null,
      name: values.name?.trim(),
      abbreviation: values.abbreviation?.trim(),
      latitude: location.lat,
      longitude: location.lng,
      country: values.country,
      countryCode: values.countryCode || null,
      branchID: values?.branchID,
      constructionStage: constructionStage || null,
      salesOfficeCountryCode: values?.salesOfficeCountryCode || null
    };

    delete formData.salesOfficeCityId;
    delete formDataUpdate.salesOfficeCityId;

    if (id) {
      saveHouse.mutate({ id, ...formDataUpdate });
    } else {
      saveHouse.mutate(formData as any);
    }
  };

  const handlePassportImage = async (e: any) => {
    const fileUpload: any = document.getElementById('fileUpload');

    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      await uploadFile.mutate(formData);
      fileUpload.value = '';
    }
  };
  const handlePassportImage2 = async (e: any) => {
    const fileUpload: any = document.getElementById('logoUpload');

    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      await uploadFile2.mutate(formData);
      fileUpload.value = '';
    }
  };

  const deleteImageHandler = (id: number) => {
    const newGallery: GalleryShow[] = [];

    for (let i = 0; i < gallery.length; i++) {
      if (gallery[i].files.id !== id) {
        newGallery.push({
          id: gallery[i].id,
          files: gallery[i].files,
          position: gallery[i].position,
          queue: i
        });
      }
    }
    setGallery(newGallery);
  };

  const handleOfferAbbreviation = (e: any) => {
    if (e.target.value.length >= 3 && !form.getFieldValue('abbreviation')) {
      form.setFieldsValue({
        abbreviation: e.target.value.substring(0, 3).toUpperCase()
      });
    }
  };

  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtID: undefined
    });
    setRegionId(id);
  };

  const params = useParams<{ orderIdOrFlatId: string }>();

  const logo = gallery?.some(item => item?.position === GalleryPosition.HOUSE_LOGO);
  const checkFieldsRequired = !(!params.orderIdOrFlatId || params?.orderIdOrFlatId?.includes('clientId'));

  const levelItem: string =
    // constructionStage === 'ROOF' ? `${t('home.Tom')} ${stages?.map(item => `${t(`home.${item}`)}`)}` : constructionStage;
    // eslint-disable-next-line no-nested-ternary
    constructionStage === 'FLOOR'
      ? `${constructionFloor} - ${t('home.qavat')}`
      : // eslint-disable-next-line no-nested-ternary
        constructionStage === 'ROOF'
        ? `${t('home.Tom')} ${stages?.length > 0 ? '|' : ''} ${stages?.map(item => t(`home.${item}`)).join(' | ')}`
        : constructionStage === 'PIT'
          ? t('home.Katlavan')
          : t('home.Bosqich_tanlang');

  const onFinishFailed = (values: any) => {
    const errors = values?.errorFields?.map((item: any) => item?.name[0]);

    if (errors?.includes('salesOfficeDistrictId')) {
      setContentVisible(true);
    }
  };

  return (
    <>
      <Modal
        closable={false}
        title={id ? t('house_config.Turar_joy_majmuasi_malumotlari') : t('house_config.Turar_joy_majmuasi_yaratish')}
        wrapClassName={styles.houseConfigModal}
        visible={editModalOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onCancel={handleCancel}
        footer={false}
        width={782}
      >
        <Spin spinning={loadingHouse}>
          <Form onFinish={onFinish} layout="vertical" form={form} onFinishFailed={onFinishFailed}>
            <Row gutter={[24, 0]} className="d-flex justify-space-between">
              <Col span={12}>
                <Form.Item label={t('home.TJM_nomi')}>
                  <Form.Item noStyle name="name" rules={[{ required: true, message: '' }]}>
                    <Input onChange={handleOfferAbbreviation} placeholder={t('house_config.TJM_nomini_kiriting')} />
                  </Form.Item>
                </Form.Item>

                <Row gutter={[8, 0]}>
                  <Col span={23}>
                    <Form.Item name="countryCode" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
                      <Select
                        style={{ width: 355 }}
                        className="customSelect"
                        value={countryCode}
                        onChange={(value, option) => {
                          setCountryCode(value);
                          form.setFieldsValue({
                            // @ts-ignore
                            country: option?.props?.country_name
                          });
                        }}
                        showSearch
                        optionFilterProp="children"
                      >
                        {countrCode?.map(item => (
                          <Select.Option
                            key={item?.code === 'uz' && clientData?.countryCode ? 'UZB' : item?.code}
                            value={item?.code}
                            props={{
                              country_name: item[`country_${i18n.language}` as keyof typeof item]
                            }}
                          >
                            {item[`country_${i18n.language}` as keyof typeof item]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {countryCode === null || countryCode === 'UZB' || countryCode === 'uz' || countryCode === undefined || (clientData?.cityID && clientData?.districtId) ? (
                    <>
                      <Col span={23}>
                        <Form.Item
                          name="cityID"
                          rules={[{ required: checkFieldsRequired, message: '' }]}
                          label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Viloyat')}</span>}
                        >
                          <Select
                            style={{ width: 355 }}
                            className="customSelect"
                            onChange={changeRegion}
                            loading={loadingRegions}
                            optionFilterProp="children"
                            showSearch
                            placeholder={t('client_create_contract.Hududni_tanlang')}
                          >
                            {regions?.map(region => (
                              <Select.Option key={region.id} value={region.id}>
                                {getCurrentLanguageWord(region)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <Form.Item label={t('client_create_contract.Shahar_tuman')}>
                          <Form.Item name="districtID" noStyle rules={[{ required: true }]}>
                            <Select
                              style={{ width: 355 }}
                              optionFilterProp="children"
                              showSearch
                              placeholder={t('client_create_contract.Hududni_tanlang')}
                              loading={districtsLoading}
                            >
                              {districts?.map(district => (
                                <Select.Option key={district.id} value={district.id}>
                                  {getCurrentLanguageWord(district)}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <Col span={16} />
                  )}
                </Row>
                <Form.Item name="country" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>

                <Form.Item label={t('house_config.TJM_manzili')}>
                  <Form.Item noStyle name="address" rules={[{ required: true, message: '' }]}>
                    <Input autoComplete="off" placeholder={t('house_config.TJM_manzilini_kiriting')} />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row gutter={[17, 0]} className="d-flex justify-space-between">
                  <Col span={12}>
                    <Form.Item label={t('house_config.Boshlanish_sanasi')}>
                      <Form.Item
                        noStyle
                        name="buildingStarts"
                        rules={[
                          { required: true, message: '', len: 10 },
                          {
                            validator: (rule, value) => {
                              if (value) {
                                form.setFieldsValue({
                                  buildingStarts: validateDateString(value, true)
                                });
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <ReactInputMask
                          mask="99.99.9999"
                          // @ts-ignore*
                          maskChar=""
                        >
                          {/* @ts-ignore */}
                          {(inputProps: any) => <Input placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
                        </ReactInputMask>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t('house_config.Yakunlanish_sanasi')}>
                      <Form.Item
                        noStyle
                        name="release"
                        rules={[
                          { required: true, message: '', len: 10 },
                          {
                            validator: (rule, value) => {
                              if (value) {
                                form.setFieldsValue({
                                  release: validateDateString(value, true)
                                });
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <ReactInputMask
                          mask="99.99.9999"
                          // @ts-ignore*
                          maskChar=""
                        >
                          {/* @ts-ignore */}
                          {(inputProps: any) => <Input placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
                        </ReactInputMask>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label={t('house_config.Masul_quruvchi')}>
                  <Form.Item name="builderID" noStyle rules={[{ required: true }]}>
                    <Select optionFilterProp="children" showSearch placeholder={t('house_config.Quruvchini_tanlang')} loading={loadingBuildings}>
                      {builders?.map(builder => (
                        <Select.Option key={builder.id} value={builder.id}>
                          {builder.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('house_config.Valyuta')}>
                  <Form.Item name="currencyId" noStyle rules={[{ required: true }]}>
                    <Select placeholder={t('house_config.Valyuta_tanlang')} loading={isLoading}>
                      {currency?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.ccy}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('house_config.TJM_nomi_qisqartmasi')}>
                  <Form.Item name="abbreviation" noStyle rules={[{ required: true, max: 3, min: 1 }]}>
                    <Input autoComplete="off" placeholder={t('house_config.TJM_nomi_qisqartmasini_kiriting')} />
                  </Form.Item>
                </Form.Item>
                <Form.Item label={t('home.Bosqich')}>
                  <Dropdown
                    overlay={
                      <div className="w-full rounded-md border-x border-y border-solid border-[#E2E4E7] bg-white px-[11px] py-[4px] shadow-md">
                        <Form.Item name="constructionStage">
                          <Select
                            placeholder={t('home.Bosqich_tanlang')}
                            onChange={e => {
                              if (typeof e === 'number') {
                                setConstructionStage('FLOOR');
                                setConstructionFloor(e);
                              } else {
                                setConstructionStage(e);
                              }
                            }}
                            className={constructionStage === 'FLOOR' ? 'customSelectFloor' : 'customSelect'}
                            value={constructionStage}
                          >
                            <Select.Option value="PIT">{t('home.Katlavan')}</Select.Option>
                            {createArray()?.map(item => (
                              <Select.Option value={item} key={item}>
                                {`${item} - ${t('home.qavat')}`}
                              </Select.Option>
                            ))}
                            <Select.Option value="ROOF">{t('home.Tom')}</Select.Option>
                          </Select>
                        </Form.Item>
                        <div className="absolute left-6 top-[11px]">{constructionStage === 'FLOOR' && `${constructionFloor} - ${t('home.qavat')}`}</div>
                        <Form.Item name="additionalStages">
                          <Checkbox.Group className="flex flex-col" disabled={Form.useWatch('constructionStage', form) !== 'ROOF'} onChange={e => setStages(e as any)}>
                            {plainOptions?.map(item => (
                              <Checkbox className="customCheckbox" key={item} value={item}>
                                {t(`home.${item}`)}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </div>
                    }
                    trigger={['click', 'hover']}
                    mouseEnterDelay={1000}
                    mouseLeaveDelay={0.3}
                  >
                    <div className="w-full cursor-pointer rounded-md border-x border-y border-solid border-[#d9d9d9] px-[11px] py-[4px]">
                      <p className="relative w-full overflow-hidden text-ellipsis whitespace-nowrap">{levelItem}</p>
                    </div>
                  </Dropdown>
                </Form.Item>
              </Col>

              {user?.accessToken && (
                <Col span={24}>
                  <Form.Item name="branchID" label={t('home.Filial_nomi')}>
                    <Select placeholder={t('home.Tanlang')} allowClear>
                      <Select.Option key={null} value={null}>
                        {t('boss_complex.Umumiy')}
                      </Select.Option>
                      {branchData?.map(item => (
                        <Select.Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <div className="w-full overflow-hidden duration-[0.3s]" style={{ height: contentVisible ? infoMaxHeight + 32 : 0 }}>
                <div className="my-4 w-full border-[0px] border-y-[1px] border-solid border-[#cccccc] bg-[#cccccc30] p-[12px]" ref={el => setInfoMaxHeight(el?.offsetHeight!)}>
                  <Form.Item label={t('house_config.Call_markazi')}>
                    <Form.Item name="callCenter" noStyle>
                      <Input autoComplete="off" placeholder="Kiriting" />
                    </Form.Item>
                  </Form.Item>
                  <hr className="h-[1px] border-0 bg-[#cccccc]" />
                  <Address.Wrapper cityId={Number(salesOfficeCityId)} countryCode={salesOfficeCountryCode}>
                    {({ cityList, countryList, districtList }) => (
                      <>
                        <div className="grid w-full grid-cols-2 gap-4">
                          <Form.Item name="salesOfficeCountryCode" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
                            <Select className="customSelect" showSearch optionFilterProp="children" allowClear>
                              {countryList?.map(item => (
                                <Select.Option
                                  key={item?.code}
                                  value={item?.code}
                                  props={{
                                    country_name: item[`country_${i18n.language}` as keyof typeof item]
                                  }}
                                  onChange={() => {
                                    form.setFieldsValue({
                                      salesOfficeCityId: undefined,
                                      salesOfficeDistrictId: undefined
                                    });
                                  }}
                                >
                                  {item[`country_${i18n.language}` as keyof typeof item]}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {cityList ? (
                            <Form.Item name="salesOfficeCityId" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Viloyat')}</span>}>
                              <Select
                                className="customSelect"
                                optionFilterProp="children"
                                showSearch
                                placeholder={t('client_create_contract.Hududni_tanlang')}
                                allowClear
                                onChange={() => {
                                  form.setFieldsValue({
                                    salesOfficeDistrictId: undefined
                                  });
                                }}
                              >
                                {cityList?.map(region => (
                                  <Select.Option key={region.id} value={region.id}>
                                    {getCurrentLanguageWord(region)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="grid w-full grid-cols-2 gap-4">
                          {cityList ? (
                            <Form.Item name="salesOfficeDistrictId" label={t('client_create_contract.Shahar_tuman')} rules={[{ required: !!salesOfficeCityId, message: '' }]}>
                              <Select optionFilterProp="children" showSearch placeholder={t('client_create_contract.Hududni_tanlang')}>
                                {districtList?.map(district => (
                                  <Select.Option key={district.id} value={district.id}>
                                    {getCurrentLanguageWord(district)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          ) : (
                            ''
                          )}
                          <Form.Item name="salesOfficeAddress" label={t('home.Manzil')}>
                            <Input placeholder={t('home.Manzil')} />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Address.Wrapper>
                  <hr className="h-[1px] border-0 bg-[#cccccc]" />
                  <div className="grid w-full grid-cols-2 gap-4">
                    <Form.Item name="instagram" label="Instagram">
                      <Input placeholder="Instagram" />
                    </Form.Item>
                    <Form.Item name="facebook" label="Facebook">
                      <Input placeholder="Facebook" />
                    </Form.Item>
                  </div>
                  <div className="grid w-full grid-cols-2 gap-4">
                    <Form.Item name="telegram" label="Telegram">
                      <Input placeholder="Telegram" />
                    </Form.Item>
                    <Form.Item name="website" label="Web sahifa">
                      <Input placeholder="Web sahifa" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="mb-4 flex w-full cursor-pointer items-center justify-center" onClick={handleContentVisible}>
                <ArrowCircleDownIcon style={{ transform: `rotate(${contentVisible ? 0 : 180}deg)` }} />
              </div>

              {logo ? (
                gallery !== null &&
                gallery
                  ?.filter(filter => filter?.position === GalleryPosition.HOUSE_LOGO)
                  .map(image => (
                    <Col span={12} key={image?.id}>
                      <HouseImage afterDelete={deleteImageHandler} id={image?.files?.id} />
                    </Col>
                  ))
              ) : (
                <Col span={12}>
                  <Spin spinning={uploadFile2?.isLoading}>
                    <label htmlFor="logoUpload" className={`d-flex align-center justify-space-between ${styles.imageUpload}`}>
                      <span className={styles.imageUpload_icon}>
                        <GalleryIcon />
                      </span>
                      <span className={styles.imageUpload_title_cont}>
                        <p className={styles.imageUpload_title}>{t('home.Logo_yuklash')}</p>
                        <p className={styles.imageUpload_info}>JPG, PNG</p>
                      </span>
                      <span>
                        <div className={styles.imageUpload_button}>{t('client_create_contract.Yuklash')}</div>
                      </span>
                    </label>
                    <input accept="image/*,application/pdf" onChange={e => handlePassportImage2(e)} type="file" style={{ display: 'none' }} id="logoUpload" />
                  </Spin>
                </Col>
              )}

              <Col span={12} style={{ marginBottom: 24 }}>
                <label className={`d-flex align-center justify-space-between ${styles.imageUpload}`}>
                  <span className={styles.imageUpload_icon}>
                    <LocationIcon />
                  </span>
                  <span className={styles.imageUpload_title_cont}>
                    <p className={styles.imageUpload_title}>{t('home.Joylashuv')}</p>
                    <p className={styles.imageUpload_info}>{locationName()}</p>
                  </span>
                  <span onClick={() => setHouseLocationVisible(true)}>
                    <div className={styles.imageUpload_button}>{t('home.Belgilash')}</div>
                  </span>
                </label>
              </Col>
              <Col span={12}>
                <Spin spinning={uploadFile.isLoading}>
                  <label htmlFor="fileUpload" className={`d-flex align-center justify-space-between ${styles.imageUpload}`}>
                    <span className={styles.imageUpload_icon}>
                      <GalleryIcon />
                    </span>
                    <span className={styles.imageUpload_title_cont}>
                      <p className={styles.imageUpload_title}>{t('house_config.Rasm_yuklash')}</p>
                      <p className={styles.imageUpload_info}>JPG, PNG</p>
                    </span>
                    <span>
                      <div className={styles.imageUpload_button}>{t('client_create_contract.Yuklash')}</div>
                    </span>
                  </label>
                  <input accept="image/*,application/pdf" onChange={e => handlePassportImage(e)} type="file" style={{ display: 'none' }} id="fileUpload" />
                </Spin>
              </Col>
              {gallery !== null &&
                gallery
                  ?.filter(filter => filter?.position !== GalleryPosition.HOUSE_LOGO)
                  .map((image, index) => (
                    <Col span={12} key={image?.files?.id}>
                      <HouseImage afterDelete={deleteImageHandler} id={image?.files?.id} />
                    </Col>
                  ))}
            </Row>
            <div className="d-flex align-center justify-space-between" style={{ marginTop: '12px' }}>
              <div />
              <div>
                <Button
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={handleCancel}
                  className={styles.cancelBtn}
                  htmlType="reset"
                >
                  {t('home.Yopish')}
                </Button>
                <Button loading={saveHouse.isLoading} className={styles.submitBtn} type="primary" htmlType="submit">
                  {id ? t('home.Saqlash') : t('client_create_contract.Yaratish')}
                </Button>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
      <HouseLocationModal houseLocationVisible={houseLocationVisible} setHouseLocationVisible={setHouseLocationVisible} setLocation={setLocation} location={location} />
    </>
  );
};

export default HouseEditAndCreateModal;
