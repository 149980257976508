import { SearchParamsArrearage } from '@pages/arrearage/Arrearage';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { getDebitsBody } from '@utils/helper/getSearchAndDownloadBodyAllTables';
import { message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { CampagingCreateModel } from '../utils/models/CampagingCreateModel';
import { SipModel } from '../utils/models/SipModel';

export function useCreateAndUpdateSip() {
  const client = useQueryClient();

  return useMutation(
    async (data: SipModel) => {
      const res = await $api.post(endPoints.CREATE_SIP, data);

      return res.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries([queryKeys.ROBOTO_SIP]);
        message.success('Muvaffaqqiyatli');
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteSip() {
  const client = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${endPoints.CREATE_SIP}/${id}`);

      return res.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries([queryKeys.ROBOTO_SIP]);
        message.success('Muvaffaqqiyatli');
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateCampaging() {
  const client = useQueryClient();

  return useMutation(
    async (data: CampagingCreateModel) => {
      const res = await $api.post(`${endPoints.ONE_CAMPAGING}`, { ...data });

      return res.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: [queryKeys.CAMPAGING_ALL] });
      }
    }
  );
}

export function useCreateRobotoDebt(searchParams: SearchParamsArrearage) {
  const client = useQueryClient();

  const paramCopy = { ...getDebitsBody(10, searchParams) };

  delete paramCopy.page;
  delete paramCopy.size;

  return useMutation(
    async (data: CampagingCreateModel) => {
      const res = await $api.post(`${endPoints.CREATE_ROBOTO_DEBT}`, { ...data, ...paramCopy });

      return res.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: [queryKeys.CAMPAGING_ALL] });
      }
    }
  );
}

export function useDeleteCompagin() {
  const client = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${endPoints.ONE_CAMPAGING}/${id}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries({ queryKey: [queryKeys.CAMPAGING_ALL] });
        message.success('Muvaffaqqiyatli');
      },
      onError: baseErrorHandler
    }
  );
}
