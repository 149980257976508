import { useQueryParam } from '@hooks/useQueryParams';
import { useQuery } from 'react-query';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { setSaleHouses } from '../../../store/reducers/HouseSlice';
import { getFlatsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { FlatTable } from '../../../utils/models/FlatTable';
import { HouseSale } from '../../../utils/models/House';
import { Pagination } from '../../../utils/models/Pagination';
import { SearchParamsHome } from '../Home';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { FlatFilterParams } from '../utils/models/FlatFilterParams';

// get sale flats list
export function useSaleFlats(size: number, searchObj: SearchParamsHome) {
  const { filter } = useAppSelector(state => state.allHouseFilter);
  const { searchParams } = useQueryParam<SearchParamsHome, any>();

  return useQuery<Pagination<FlatTable>>(
    [queryKeys.FLAT_LIST, searchObj.page, JSON.stringify(searchObj)],
    async () => {
      const res = await $api.post(endPoints.FLAT_FILTER, {
        ...getFlatsBody(size, searchObj),
        // eslint-disable-next-line no-nested-ternary
        fields: Array.isArray(searchParams?.fields)
          ? searchParams?.fields?.map((item: string) => `${item?.split('_')[0]}:${decodeURI(item?.split('_')[1])}`)
          : searchParams?.fields?.split('_')[0]
            ? [`${searchParams?.fields?.split('_')[0]}:${decodeURI(String(searchParams?.fields?.split('_')[1]))}`]
            : null
      });

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get flat filters
export function useFlatTableFilters() {
  return useQuery<FlatFilterParams>(
    [queryKeys.FLAT_FILTER],
    async () => {
      const res = await $api.get(endPoints.FILTER_PARAMS);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );
}

// get sale houses list
export function useSaleHouses(page: number, totalPage: number, search?: string) {
  const dispatch = useAppDispatch();
  let url = `${endPoints.HOUSE_EXTRA}?page=${page}&size=10`;

  if (search) {
    url += `&name=${search}`;
  }
  return useQuery<Pagination<HouseSale>>(
    [queryKeys.HOUSE_SHOW, page, search],
    async () => {
      const res = await $api.get(url);

      return res?.data?.data;
    },
    {
      enabled: page !== 0 && page <= totalPage,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onSuccess: (data: Pagination<HouseSale>) => {
        dispatch(setSaleHouses(data));
      },
      onError: baseErrorHandler,
      retry: false
    }
  );
}
