import CompanyPermissionConfig from '@pages/company-permission-config/CompanyPermissionConfig';
import VoronkaShablon from '@pages/voronka-shablon/VoronkaShablon';
import WebsiteUser from '@pages/website-user/WebsiteUserMain';

import Companies from '../../pages/companies/Companies';
import CompanyEmployeeRoles from '../../pages/companyEmployeeRoles/CompanyEmployeeRoles';
import CompanyEmployees from '../../pages/companyEmployees/CompanyEmployees';
import MonetaryConfig from '../../pages/monetary-config/MonetaryConfig';
import NotificationDeveloper from '../../pages/notificationDeveloper/NotificationDeveloper';
import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { User } from '../../utils/models/User';

import { DeveloperPaths } from './developerPaths';

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: DeveloperPaths.INDEX,
    component: Companies,
    exact: true,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.COMPANY_EMPLOYEES,
    component: CompanyEmployees,
    exact: true,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.PERMISSIONS,
    component: CompanyEmployeeRoles,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.NOTIFICATION,
    component: NotificationDeveloper,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.MONETARY,
    component: MonetaryConfig,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.COMPANY_PERMISSION_CONFIG,
    component: CompanyPermissionConfig,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.VORONKA_SHABLON,
    component: VoronkaShablon,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  },
  {
    path: DeveloperPaths.WEBSITE_USER,
    component: WebsiteUser,
    exact: false,
    permission: Permissions.PERMISSION_DEVELOPER
  }
];

const developerRoutes: typeof routes = [];

if (typeof user === 'object' && user) {
  routes.forEach(route => {
    if (route?.permission) {
      if (user?.role?.permissions?.includes(route?.permission)) {
        developerRoutes.push(route);
      }
    } else {
      developerRoutes.push(route);
    }
  });
}

export default developerRoutes;
