import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';

import { countryList } from '../constants';
import { useAddressCity, useAddressDistrict } from '../hooks';

interface IProps {
  children: (props: { countryList: typeof countryList; cityList?: BaseLanguageEntity[]; districtList?: BaseLanguageEntity[] }) => React.ReactNode;
  countryId?: number | null;
  countryCode?: string;
  cityId?: number | null;
}

const Wrapper = ({ children, countryId, cityId, countryCode }: IProps) => {
  const { data: cityList } = useAddressCity({ countryId });
  const { data: districtList } = useAddressDistrict({ cityId });

  return <>{children({ countryList, cityList: countryCode === 'uz' ? cityList : undefined, districtList })}</>;
};

export default Wrapper;
