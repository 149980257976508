import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { ChartDataModel, ChartDataModel1 } from '@pages/dashboard/utils/models/ChartDataModel';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import i18n from '@service/I18nService';
import { widgetPieChartColord } from '@utils/constants/colorPieChart';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { colorGenerator } from '@utils/helper/colorGenerator';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { CrmPieChartModel } from '@utils/models/CrmLeadList';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import styles from './chartWidget.module.scss';

type Props = {
  data: (CrmPieChartModel & ChartDataModel) | undefined;
  item: WidgetModel;
  loading: boolean;
};

const ChartWidget: React.FC<Props> = ({ data, item, loading }) => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<any[]>();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const objectData = JSON.parse(item?.objectData.replaceAll(/'/g, '"'));
  const objectType = item?.objectType;

  let queryString1 = '';

  useEffect(() => {
    for (const key in objectData) {
      if (Array.isArray(objectData[key])) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        objectData[key].forEach((value: string) => {
          queryString1 += `${key}=${encodeURIComponent(value)}&`;
        });
      } else {
        queryString1 += `${key}=${encodeURIComponent(objectData[key])}&`;
      }
    }
    queryString1 = queryString1.slice(0, -1);
  }, [objectData]);

  const handleNavigateFilter = () => {
    if (objectType === 'LEAD') {
      navigate(`${rootPaths.MAIN + MainPaths.CRM}?${queryString1}`);
    } else if (objectType === 'TASK') {
      navigate(`${rootPaths.MAIN + MainPaths.TASK}?${queryString1}`);
    } else {
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${queryString1}`);
    }
  };

  const handleNavigateDateFilter = () => {
    if (objectType === 'LEAD') {
      navigate(
        `${
          rootPaths.MAIN + MainPaths.CRM + (objectData?.voronka ? `?${queryString1}` : `?voronka=${objectData?.pipeId}&${queryString1}`)
        }&startDate=${`${searchParams?.startDate} 00:00`}&finishDate=${`${searchParams?.finishDate} 23:59`}`
      );
    } else if (objectType === 'TASK') {
      navigate(
        `${rootPaths.MAIN + MainPaths.TASK}?${queryString1}` +
          `&startDate=${urlEncode(dayjs(searchParams?.startDate, DATE_FORMAT).hour(0).minute(0).format(DATE_WITH_TIME_FORMAT))}&finishDate=${urlEncode(dayjs(searchParams?.finishDate, DATE_FORMAT).hour(23).minute(59).format(DATE_WITH_TIME_FORMAT))}`
      );
    } else {
      // eslint-disable-next-line no-useless-concat
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${queryString1}` + `&startDate=${searchParams?.startDate}&finishDate=${searchParams?.finishDate}`);
    }
  };

  useEffect(() => {
    if (data?.filter) {
      const newData: ChartDataModel1[] = [];

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(data?.filter?.leadList) &&
        data?.filter?.leadList.forEach((house, index) => {
          let color = widgetPieChartColord[index];

          if (index > 8) {
            color = `#${colorGenerator()}`;
          }
          newData.push({
            balance: house?.balance,
            count: house?.count,
            field: objectType === 'LOG' && item?.groupBy !== 'EMPLOYEE' ? String(house[i18n.language as keyof typeof house]) : house?.field,
            color
          });
        });

      setChartData(newData?.sort((a, b) => b.count - a.count));
    }
  }, [data?.filter]);

  console.log(objectType);

  return (
    <>
      <Spin spinning={loading}>
        <div className={styles.chartWidget} style={{ cursor: updateWidget ? 'grab' : '' }}>
          <div className={styles.chartWidget_header}>
            <div className={styles.chartWidget_header_left} onClick={handleNavigateFilter}>
              <h3>{data?.filter?.overallCount}</h3>
              {objectType === 'LEAD' && <span>{data?.filter?.totalBalance?.toLocaleString('ru') || 0} UZS</span>}
            </div>
            {data?.filterWithDate && (
              <div className={styles.chartWidget_header_right} onClick={handleNavigateDateFilter}>
                <h3>{data?.filterWithDate?.overallCount}</h3>
                <span>
                  {searchParams?.startDate} - {searchParams?.finishDate}
                </span>
              </div>
            )}
          </div>
          <div className={styles.chartWidget_line} />
          <div className={styles.chartWidget_elements}>
            {chartData?.map((item, index) => {
              const filterCount = data?.filterWithDate && data?.filterWithDate?.leadList?.find(el => el?.field === item?.field)?.count;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.chartWidget_elements_item} key={index}>
                  <div className={styles.chartWidget_elements_item_top}>
                    <div className={styles.chartWidget_elements_item_top_left}>
                      <div style={{ borderColor: item?.color }} className={styles.circle} />
                      <span>{item?.field}</span>
                      <span>{item?.count}</span>
                      {objectType === 'LEAD' && <span>({item?.balance} UZS)</span>}
                    </div>
                    <span className={styles.chartWidget_elements_item_top_left_filterCount}>{data?.filterWithDate && (filterCount || 0)}</span>
                  </div>
                  <div className={styles.chartWidget_elements_item_bottom}>
                    <div
                      style={{
                        width: `${(item?.count / data?.filter?.overallCount!) * 100}%`,
                        backgroundColor: item?.color,
                        height: '100%',
                        borderRadius: 8
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default ChartWidget;
