import { PaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/utils/models/PaymentCustomType';

import { CurrencyItem } from './Currency';

export interface BossHeader {
  month: number;
  all: number;
  balance: BossClientBalance[];
}

export interface BossClientBalance {
  amount: number;
  currency: CurrencyItem;
}

export interface BossHomeFlatsCountType {
  apartment: number;
  nonResidential: Record<string, number>;
}

export interface BossHomeFlatsCount {
  empty: BossHomeFlatsCountType;
  booked: BossHomeFlatsCountType;
  sold: BossHomeFlatsCountType;
}

export interface BossHomePayments {
  all: number;
  card: number;
  cash: number;
  transfer: number;
  balance: number;
  bank: number;
  myUzCard: number;
  payme: number;
  others: number;
  apelsin: number;
  uzum: number;
  custom: PaymentCustomType[];
  totalPaymentByBase: TotalPaymentByBase;
}

export interface TotalPaymentByBase {
  CLIENT: TotalPaymentByBaseKeys;
  CONTRACT: TotalPaymentByBaseKeys;
  SURCHARGE: TotalPaymentByBaseKeys;
  BOOKING: TotalPaymentByBaseKeys;
  OUTCOME: TotalPaymentByBaseKeys;
}

export enum TotalPaymentByBaseEnum {
  CLIENT = 'CLIENT',
  CONTRACT = 'CONTRACT',
  SURCHARGE = 'SURCHARGE',
  BOOKING = 'BOOKING',
  OUTCOME = 'OUTCOME'
}

export interface TotalPaymentByBaseKeys {
  all: number;
  apelsin: number;
  balance: number;
  bank: number;
  card: number;
  cash: number;
  myUzCard: number;
  payme: number;
  transfer: number;
}
